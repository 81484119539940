import React from 'react';
import { useCookies } from "react-cookie";
import { Card, CardContent, Typography,  Grid, Button } from '@mui/material';
import { OrganizationHierarchyNode } from './types'; // Assuming you have types defined appropriately
import { User } from "../../interfaces/user/User";
import { Table, TableBody, TableCell, TableHead, TableRow,  Dialog, DialogTitle, DialogContent, DialogActions} from '@mui/material';
import UserService from "../../services/UserService";
import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

interface Props {
  node: OrganizationHierarchyNode;
}


const AdminUsers: React.FC<Props> = ({ node }) => {
  const [assignedAdmins, setAssignedAdmins] = useState <User []> ([])
  const [availableAdmins, setAvailableAdmins] = useState <User []> ([])
  const [implicitAdmins, setImplicitAdmins] = useState <User []> ([])
  const [cookies] = useCookies(['access_token']);
  const { t } = useTranslation();
  const [confirmDelete, setConfirmDelete] = useState (false);
  const [selectedUser, ] = useState <User | null> (null)
  //const theme = useTheme();

  useEffect (() => {
    //console.log ("Retrieve data for node:", node)
    // Retrieve the users for the currenty selected hierarchy node. We use node_name to identify
    // the node, and should get all mgmt users below my own level.
    UserService.getNodeAdmins (cookies.access_token, node.node_name).then ((response: any) => {
      //console.log ("getNodeAdmins response is ", response)
      if (response?.availableAdmins) {
        setAvailableAdmins    (response.availableAdmins)
      }

      if (response?.assignedAdmins) {
        setAssignedAdmins (response.assignedAdmins)
      }

      if (response?.implicitAdmins) {
        setImplicitAdmins (response.implicitAdmins)
      }

    })
		// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node])

  useEffect (() => {
    //console.log ("availableAdmins List is ", availableAdmins)
  }, [availableAdmins])

  useEffect (() => {
    //console.log ("assignedAdmins List is ", assignedAdmins)
  }, [assignedAdmins])

  useEffect (() => {
    //console.log ("implicitAdmins List is ", implicitAdmins)
  }, [implicitAdmins])


  const cancelDelete = () => {
    setConfirmDelete (false)
  };

  const yesDelete = () => {
    alert ("DELETE SELECTED USER: NOT IMPLEMENTED YET")
    if (selectedUser) { // currently null
      removeUser (selectedUser)
    }
    setConfirmDelete (false)
  }
  /*
  const handleRemoveUser = (user: User) => {
    setSelectedUser (user);
    setConfirmDelete (true)
  }
  */

  const removeUser = (user: User) => {
    // Logic to remove a user
    console.log('Remove user', user);
    UserService.removeNodeAdmin (cookies.access_token, node.node_name, user.username || "").then ((response: any) => {
      console.log ("removeNodeAdmin response is ", response)
      if (response?.availableAdmins) {
        setAvailableAdmins    (response.availableAdmins)
      }

      if (response?.assignedAdmins) {
        setAssignedAdmins (response.assignedAdmins)
      }

      if (response?.implicitAdmins) {
        setImplicitAdmins (response.implicitAdmins)
      }
    })
  };

  if (!node) {
    return (
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h6" color="textSecondary">
            Select a node to view admin users
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card variant="outlined">
        <Dialog open={confirmDelete} >
            <DialogTitle> {t ("confirm_adminuser_remove") } </DialogTitle>
            <DialogContent>
                <Typography>{t("adminuser_remove_notification")}</Typography>
            </DialogContent>
            <DialogActions>
                <Grid container direction="row" justifyContent="space-between"  alignItems="center" >
                    <Button variant="outlined" onClick={cancelDelete}> { t("cancel") }</Button>
                    <Button variant="contained" onClick={yesDelete} color="warning">  { t("yes") }</Button>
                </Grid>
            </DialogActions>
        </Dialog>

      <CardContent>
        <Table size="small" >
          <TableHead >
            <TableRow>
              <TableCell>
                <Typography fontWeight="bold" sx={{ color: (theme) => theme.palette.text.primary}}>Last Name</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight="bold" sx={{ color: (theme) => theme.palette.text.primary}}>First Name</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {implicitAdmins.map((user) => (
              <TableRow key={user.username}
              sx={{ color: 'gray', opacity: 0.5 }} // Apply gray styling here

              >
                <TableCell> <Typography sx={{ color: (theme) => theme.palette.text.secondary}}> {user.last_name}</Typography></TableCell>
                <TableCell> <Typography sx={{ color: (theme) => theme.palette.text.secondary}}>{user.first_name}</Typography></TableCell>
                
              </TableRow>
            ))}



            {assignedAdmins.map((user) => (
              <TableRow key={user.username}>
                <TableCell> <Typography sx={{ color: (theme) => theme.palette.text.primary}}>{user.last_name}</Typography></TableCell>
                <TableCell> <Typography sx={{ color: (theme) => theme.palette.text.primary}}>{user.first_name}</Typography></TableCell>
                
                {/*<TableCell align="right">
                <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => handleRemoveUser(user)}
                                size="small"
                                sx={{
                                    verticalAlign: 'middle',
                                    padding: 0, // Reduce padding for smaller size
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    component="span"
                                    sx={{
                                        cursor: 'pointer',
                                        color: 'red', // Adjust color if needed
                                    }}
                                >
                                    &minus;
                                </Typography>
                            </IconButton>
                </TableCell>
                */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );

};

export default AdminUsers;
