import {
	Grid,
//	Typography,
	useTheme,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	InputLabel,
	MenuItem,
	Switch,
	TextField,
} from "@mui/material";
import { Select, SelectChangeEvent } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Device } from "../../interfaces/Device";
import { useState, useEffect } from "react";
//import Card from "@mui/material";
//import CardContent from "@mui/material";
//import { AlarmProfile } from "../Home/Device/AlarmProfile";
import { AlarmProfileSelect } from "../Home/Device//AlarmProfileSelect";
import { useCookies } from "react-cookie";
import DeviceService from "../../services/DeviceService";
//import QRCode from "react-qr-code";
// import * as CONSTANTS from "../../../utils/constants/constants";
import VideoLinkCard from '../../components/VideoLinkCard';
import { Typography } from '@mui/material';


interface ItemProps {
	title: any;
	content: any;
}

export const CardItem = ({ title, content }: ItemProps) => {
	return (
		<Grid container>
			<Grid item xs={6}>
				<Typography>{title} </Typography>
			</Grid>
			<Grid item xs={6}>
				<Typography>{content}</Typography>
			</Grid>
		</Grid>
	);
};
interface CustomInputProps {
    labelKey: string;
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    changed?: boolean;
    error?: boolean;
    disabled?: boolean;
    sx?: object; // You can define a more specific type for styling if needed
    // Add any other props you might need
}

const CustomInput: React.FC<CustomInputProps> = ({ 
    labelKey, 
    value, 
    onChange, 
    changed = false,
    error = false,
    disabled = false,
    sx = {}, 
    ...props 
}) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const inputStyle = {
        color: theme.palette.text.primary,
        background: changed ? 'orange' : theme.palette.background.default,
    };
    const errorStyle = error ? { border: '1px solid red' } : {};

    return (
        <TextField
            fullWidth
            InputLabelProps={{ shrink: true }}
            label={t(labelKey)}
            value={value}
            onChange={onChange}
            variant = "outlined"
            error={error}
            disabled = {disabled}
            sx={{
                input: inputStyle,
                '& .MuiOutlinedInput-root': {
                    ...errorStyle,
                },
                label: {
                    color: 'theme.palette.text.primary', // Set a specific color
            '&.Mui-focused': {
                color: theme.palette.text.primary, // Ensure color stays the same even when focused
            },
                },
                ...sx, // Allow additional styles to be passed
            }}
            {...props} // Spread any other props passed to the component
        />
    );
};



interface DeviceProps {
    action:       string;  // "add" or "edit"
	device?:      Device;  // missing if it's a new ADD device
	alarmProp:    any;            // alarm properties
	openState:    boolean;
	setOpenState: (show: boolean) => void;
	deviceAction: (device: Device, action?: string) => void;
}


// ---------------------------------------------------------------------------------------------------------
export const DeviceMgmtDialog = ({
    action,
	device,
	alarmProp,
	openState,
	setOpenState,
    deviceAction
}: DeviceProps) => {
    //console.log ("DeviceMgmtDialog:", device, alarmProp)
	// @ts-ignore
	//const user: any    = JSON.parse(localStorage.getItem("user"));
	const [cookies, ,] = useCookies(["access_token"]);
	const theme        = useTheme();
	const { t }        = useTranslation();
	const isPharlap    = localStorage.getItem("SYSTEM_TYPE") === "PHARLAP";
	const videoLinkCapable: boolean = (device?.camera_id != null && device?.camera_id !== "")

    const deviceMenu = alarmProp.deviceMenu;    

    const [selectedModel, setSelectedModel] = useState(device ? device.device_model : '');
    const [modelOptions, setModelOptions] = useState<string[]>([]);

    // Fields that can be modified have useState variables associated with them
    const [selectedDeviceType, setSelectedDeviceType] = useState<string>(device ? device.device_type : '');
	const [deviceId, setDeviceId] = useState<string>(device ? device.device_id : "");
	const [nickname, setNickname] = useState<string>(device ? device.nickname : "");
	const [serialnumber, setSerialnumber] = useState<string>(device ? device.device_serialnumber : "");
	const [location, setLocation] = useState<string>(device ? device.location : "");
	const [tempUnit, setTempUnit] = useState<string>(device ? device.temp_unit : "C");
	const [mfgInfo, setMfgInfo]   = useState<string>(device ? device.mfg_device_info : "");
	const [notes, setNotes]       = useState<string>(device ? device.notes : "");
	const [cameraId, setCameraId] = useState<string>(device?.camera_id ? device.camera_id : "");
    const [deviceIdError, setDeviceIdError] = useState (false);

	//const [alarmsActivation, setAlarmsActivation]      = useState("");
	//const [alarmsDeactivation, setAlarmsDeactivation]  = useState("");
    const [showSaveButton, setShowSaveButton] = useState(false);

    // Pharlap Specific variables related to Video Link
	const deviceVideoLink: string   = device?.video_link ? device.video_link : "";
	const deviceVideoExpiry: string = device?.video_expiry ? device.video_expiry : "";	

    // Scoring Profiles / Alarm Profiles
	const [selectedAlarmProfile, setSelectedAlarmProfile] = useState<string>(device ? device.alarm_profile_key : "NONE"); 
	const [selectedAlarmProfileDef, setSelectedAlarmProfileDef] = useState (device ? JSON.parse (device.alarm_profile) :
                                                                                      alarmProp.alarm_profile_defs["NONE"])
	const [eqpAlarmsEnable, setEqpAlarmsEnable]       = useState(selectedAlarmProfileDef.eqp_alarms);
	const [sentioAlarmsEnable, setSentioAlarmsEnable] = useState(device ? device.enable_alarms : false);
    const [errorMessage, setErrorMessage] = useState <string> ('');
    const [confirmDelete, setConfirmDelete] = useState (false);


    // Callback Functions
	const handleClose = () => {
		//setIsEditingThresholds(false);
		setOpenState(false);
        setErrorMessage ("")
        setDeviceIdError (false);
	};

    //const handleDeviceTypeChange = (event: SelectChangeEvent<string>) => {
    //    setSelectedDeviceType(event.target.value);
    //};

    const checkSaveButton = () => {
        const showSave = deviceId !== "" && selectedDeviceType !== "" && selectedModel !== "";
        setShowSaveButton (showSave)
    }
    const handleSerialnumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSerialnumber(event.target.value);
    }
    const handleNicknameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNickname(event.target.value);
    }
    const handleMfgInfoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMfgInfo(event.target.value);
    }
    const handleNotesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNotes(event.target.value);
    }
    const handleDeviceIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDeviceId(event.target.value);
    }

	const handleLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLocation(event.target.value);
	};

	const handleTempUnitChange = (event: SelectChangeEvent) => {
		setTempUnit(event.target.value);
	};


	const handleEqpEnableAlarmsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEqpAlarmsEnable(event.target.checked);

        // The equipment alarm enable/disable is in the alarm profile for the device.
        console.log ("Update eqp alms:", selectedAlarmProfileDef)
        const newDef = JSON.parse (JSON.stringify (selectedAlarmProfileDef));
        newDef.eqp_alarms = event.target.checked
        setSelectedAlarmProfileDef (newDef)
	};

	const handleSentioAlarmsEnable = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSentioAlarmsEnable(event.target.checked);
	};



    const handleCameraIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCameraId(event.target.value);
    }

    const changeAlmProfile = (p: any): void => {
        console.log ("New profile:", p)
		setSelectedAlarmProfileDef(p);
		//(p.raise_holdoff);
		//setAlarmsDeactivation(p.clear_holdoff);
	};
    // Pharlap only:
	const setDeviceVideoLink = (link: string, expiry:string): void => {
		if (device) {
			device.video_link   = link;
			device.video_expiry = expiry;
		}
	} // setDeviceVideoLink

    const saveNewDevice = (device: Device) => {
        DeviceService.addDevice(
			device,
			cookies.access_token
		).then((res: any) => {
			//console.log ("Result:", res)
            
            if (res) {
                switch (res.status) {
                    case 200: {
                        deviceAction (device)
                        setOpenState (false)
                        setErrorMessage ("")
                        setDeviceIdError (false);
                        break;
                    }
    
                    case 409: {
                        setErrorMessage ("Could not add device: Device ID already exists")
                        setDeviceIdError (true);
                        break;
                    }
                    default: {
                        setDeviceIdError (false);
                        console.log("Error in updating device:", device);
                        console.log ("res = ", res)
                    }
                }    
            } else {
                setErrorMessage ("Error saving device")
            }
            
		});
    } // saveNewDevice

    const editDevice = (device: Device) => {
        DeviceService.editDevice(
			device,
			cookies.access_token,
			device.device_id
		).then((res: any) => {
			console.log ("Edit Result:", res)
            if (res) {
                switch (res.status) {
                    case 200: {
                        console.log ("device:  ", device)
                        console.log ("res.data:", res.data)
                        deviceAction (res.data, "edit")
                        setOpenState (false)
                        setErrorMessage ("")
                        setDeviceIdError (false);
                        break;
                    }
    
                    case 409: {
                        setErrorMessage ("Could not modify device: Device ID already exists")
                        setDeviceIdError (true);
                        break;
                    }
                    default: {
                        setDeviceIdError (false);
                        console.log("Error in updating device:", device);
                        console.log ("res = ", res)
                    }
                }    
            } else {
                setErrorMessage ("Error saving device")
            }
		});
    }

    const handleSaveChanges = (device?: Device) => {
        console.log ("handleSaveChanges:", device)
        const deviceTarget: Device = {
            device_type:         selectedDeviceType,
            device_model:        selectedModel,
            device_id:           deviceId,
            device_serialnumber: serialnumber,
            notes:               notes,
            location:            location,
            temp_unit:           tempUnit,
            nickname:            nickname,
            mfg_device_info:     mfgInfo,
            enable_alarms:       sentioAlarmsEnable,
            qr_code:             device ? device.qr_code : "",
            VICU_assignment:     device ? device.VICU_assignment : "",
            alarm_profile:       JSON.stringify (selectedAlarmProfileDef),
            alarm_profile_key:   selectedAlarmProfile, 
            active_alm_profile:  device ? device.active_alm_profile : "",
            device_def:          device ? device.device_def : "",
            case_id:             device ? device.case_id : "",
            email_notification:  device ? device.email_notification : "",
            dynamic_data:        device ? device.dynamic_data : {},
            valid_subscription:  device ? device.valid_subscription : true,
            monitor_groups:      device ? device.monitor_groups : [],
            camera_id:           cameraId
        }

        switch (action) {
            case "add" : {
        
                saveNewDevice (deviceTarget);
                break;
            } // add

            case "edit" : {
                editDevice (deviceTarget);
                break;
            }
        }

		
	}; // handleSaveChanges
    const handleDelete = () => {
        setConfirmDelete (true)
    };

    const cancelDelete = () => {
        setConfirmDelete (false)
    };

    const yesDelete = () => {
        // Really delete the VICU. Send a request to the back-end
        if (!device) {
            return;
        }
        DeviceService.deleteDevice  (device.device_id, cookies.access_token)
        .then((res: any) => {
            deviceAction (device, "delete")
            setConfirmDelete (false)
            setOpenState (false)

            console.log(res);
        })
        .catch((err: any) => {
            console.log(err);
        });

    };


    const handleDeviceTypeChange = (event: SelectChangeEvent) => {
        setSelectedDeviceType(event.target.value as string);
    };

    const handleModelChange = (event: SelectChangeEvent) => {
        setSelectedModel(event.target.value as string);
    };

    useEffect (() => {
        checkSaveButton ();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deviceId, selectedDeviceType, selectedModel])

    useEffect(() => {
        if (selectedDeviceType) {
            const models = Object.keys(deviceMenu[selectedDeviceType]);
            setModelOptions(models);
            setSelectedModel(models[0] || ''); // Automatically select the first model if available
        } else {
            setModelOptions([]);
            setSelectedModel('');
        }
    }, [selectedDeviceType, deviceMenu]);


    var changed = false;
    const sortedDeviceTypes = Object.keys(deviceMenu).sort();

    return (
		<Dialog
			open={openState}
			onClose={handleClose}
			PaperProps={{ sx: { width: "100%" } }}
			maxWidth="lg"
			scroll="paper"
		>
			<DialogTitle > {action === "add" ? t("add_new_device") : t ("modify_device")} </DialogTitle>

			<DialogContent >
                <Grid container columnSpacing={2} sx={{ marginTop: 0.5 }} alignItems="flex-start">

                    <Grid item xs={3} mb={1}>
                        <FormControl fullWidth margin="normal" style={{ height: '100%' }}>
                            <InputLabel sx={{ color: theme.palette.text.primary }} id="device-type-select-label" shrink={true} >{t("device_type")}</InputLabel>
                            <Select
                                labelId="device-type-select-label"
                                id="device-type-select"
                                value={selectedDeviceType}
                                label={t("device_type")}
                                disabled = {action !== "add"}
                                onChange={handleDeviceTypeChange}
                            >
                                {sortedDeviceTypes.map((deviceType, index) => (
                                    <MenuItem key={index} value={deviceType}>
                                        {deviceType}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={3} mb={1}>
                        <FormControl fullWidth margin="normal" style={{ height: '100%' }}  disabled={!selectedDeviceType}>
                            <InputLabel sx={{ color: theme.palette.text.primary }} id="model-selector-label" shrink={true}>{t("device_model")}</InputLabel>
                            <Select
                                labelId="model-selector-label"
                                id="model-selector"
                                value={selectedModel}
                                label={t("device_model")}
                                disabled = {action !== "add"}
                                onChange={handleModelChange}
                            >
                                {modelOptions.map((model, index) => (
                                    <MenuItem key={index} value={model}>
                                        {model}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={3} mb={1}>
                        <CustomInput 
                            labelKey={t("device_id")}
                            value={deviceId}
                            onChange={handleDeviceIdChange}
                            changed={changed}
                            error={deviceIdError}
                            disabled = {action !== "add"}
                            sx={{ marginTop: 2 }} // Adjust this as needed
                        />
                    </Grid>

                    <Grid item xs={3} mb={1}>
                        <CustomInput 
                            labelKey={t("serial_number")}
                            value={serialnumber}
                            onChange={handleSerialnumberChange}
                            changed={changed}
                            sx={{ marginTop: 2 }} // Adjust this as needed
                        />
                    </Grid>
                </Grid> {/* Line 1 */}


				<Grid container columnSpacing={2}>
					<Grid item xs={3} mb={1}>
                        <CustomInput 
                                labelKey={t("nickname")}
                                value={nickname}
                                onChange={handleNicknameChange}
                                changed={changed}
                        />
					</Grid>
					<Grid item xs={3} mb={1}>
                        <CustomInput 
                                labelKey={t("location")}
                                value={location}
                                onChange={handleLocationChange}
                                changed={changed}
                        />
					</Grid>
                    <Grid item xs={2} mb={1}>
                        <FormControl fullWidth>
                            <InputLabel
                                sx={{ color: "text.primary" }}
                                id="tempunit"
                            >
                                {t("temp_unit")}
                            </InputLabel>
                            <Select
                                value={tempUnit}
                                label={t("temp_unit")}
                                onChange={handleTempUnitChange}
                            >
                                <MenuItem key={"Celcius"} value={"C"}>
                                    {t("celcius")}
                                </MenuItem>
                                <MenuItem key={"Fahrenheit"} value={"F"}>
                                    {t("fahrenheit")}
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    {!isPharlap && (
                        <Grid item xs={4} mb={1}>
                            <AlarmProfileSelect
                                profileDefs={alarmProp.alarm_profile_defs}
                                selectedProfile={selectedAlarmProfile}
                                setProfileDef={changeAlmProfile}
                                setProfileSelect={setSelectedAlarmProfile}
                                title={t("scoring_method")}
                            />
                        </Grid>
                    )}
                    
                    
                </Grid> {/* Line 2 */}

                <Grid container columnSpacing={2}>
                    <Grid item xs={3} mb={1}>
                        <CustomInput 
                            labelKey={t("camera_id")}
                            value={cameraId}
                            onChange={handleCameraIdChange}
                            changed={changed}
                        />
                    </Grid>
                    <Grid item xs={4} mb={1}>
                            <CustomInput 
                                    labelKey={t("mfg_info")}
                                    value={mfgInfo}
                                    onChange={handleMfgInfoChange}
                                    changed={changed}
                            />
					</Grid>
                    <Grid item xs={5} mb={1}>
                            <CustomInput 
                                    labelKey={t("notes")}
                                    value={notes}
                                    onChange={handleNotesChange}
                                    changed={changed}
                            />
					</Grid>
                </Grid> {/* Line 3 */}

                
                <Grid container columnSpacing={1} direction="row">
                    <Grid container item justifyContent="flex-start" xs={4} sx={{marginLeft:2}}>
                        <Typography
                            padding={1}
                            color="text.primary"
                        >
                            {t("enable_equipment_alarms")}
                        </Typography>
                        <Typography
                            padding={1}
                            color="text.primary"
                        >
                            {t("off")}
                        </Typography>
                        <Switch
                            checked={eqpAlarmsEnable}
                            onChange={handleEqpEnableAlarmsChange}
                        />
                        <Typography
                            padding={1}
                            color="text.primary"
                        >
                            {t("on")}
                        </Typography>
                    </Grid>

                    <Grid container item justifyContent="flex-start" xs={4} sx={{marginLeft:2}}>
                        <Typography
                            padding={1}
                            color="text.primary"
                        >
                            {t("enable_sentioweb_alarms")}
                        </Typography>
                        <Typography
                            padding={1}
                            color="text.primary"
                        >
                            {t("off")}
                        </Typography>
                        <Switch
                            checked={sentioAlarmsEnable}
                            onChange={handleSentioAlarmsEnable}
                        />
                        <Typography
                            padding={1}
                            color="text.primary"
                        >
                            {t("on")}
                        </Typography>
                    </Grid>

                </Grid> {/* Line 4 */}

                { isPharlap && (

                        <Grid container columnSpacing={2}>
                            <Grid item xs={12} mb={1}>
                                { videoLinkCapable &&
                                    <Grid item xs={12} >
                                        <VideoLinkCard
                                            device_id = {device?.device_id}
                                            initialVideoLink={deviceVideoLink}
                                            initialEnabled={true}
                                            initialExpirationTime={new Date (deviceVideoExpiry)}
                                            setDeviceVideoLink = {setDeviceVideoLink }
                                        />
                                    </Grid>
                                }
                            </Grid>
                        </Grid> 

                )}
				
			</DialogContent>

            <Grid container >
                <Grid item xs={6}>
                    {errorMessage !== "" && 
                    <Typography sx={{marginLeft: 4}} fontSize={20} color = {theme.palette.error.main}>
                        {errorMessage}
                    </Typography>
                    }
                </Grid>
                <Grid item xs={6}>
                    <DialogActions>
                        <Grid container spacing={2}>
                            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                { action !== "add" && <Button onClick={handleDelete} color="secondary">
                                    {t("delete_device")}
                                </Button>
                                }
                            </Grid>                        
                            {showSaveButton && (
                                <Button onClick={() => handleSaveChanges(device)}>
                                    {t("save_changes")}
                                </Button>
                            )}

                            <Button onClick={handleClose}> {t("close")}</Button>
                        </Grid>
                    </DialogActions>

                </Grid>
            </Grid>
            <Dialog open={confirmDelete} >
            <DialogTitle> {t ("confirm_device_delete") } </DialogTitle>
            <DialogContent>
                <Typography>{t("delete_device_notification")}</Typography>
            </DialogContent>
            <DialogActions>
                <Grid container direction="row" justifyContent="space-between"  alignItems="center" >
                    <Button variant="outlined" onClick={cancelDelete}> { t("cancel") }</Button>
                    <Button variant="contained" onClick={yesDelete} color="warning">  { t("yes_delete") }</Button>
                </Grid>
            </DialogActions>
        </Dialog>

		</Dialog>

        
	);
}



    

/*

				<Grid
					container
					item
					sx={{ marginTop: 1 }}
					justifyContent="center"
				>
					<AlarmProfile
						profile={selectedAlarmProfileDef}
						alarms={alarms}
						isEditingThresholds={isEditingThresholds}
						setAlarms={setAlarms}
						setIsEditingThresholds={setIsEditingThresholds}
					/>
				</Grid>



				<Button onClick={editThresholds}>
					{isEditingThresholds ? t("save") : t("edit_thresholds")}
				</Button>
  
*/