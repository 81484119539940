import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

interface MessageAlertProps {
  open: boolean;  // Control the visibility of the dialog
  message: string[]; // Message to display
  onClose: () => void; // Function to call when closing the dialog
}

const MessageAlert: React.FC<MessageAlertProps> = ({ open, message, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{message[0]}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message[1]}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MessageAlert;
