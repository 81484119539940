import { FormControl, InputLabel, Select, MenuItem, Typography } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';

interface CustomSelectProps<T> {
  label: string;
  options: T[];
  valueExtractor: (option: T) => string;
  descriptionExtractor: (option: T) => string;
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  sx?: SxProps<Theme>;  // This allows the component to accept `sx` prop for styling
}

const CustomSelect = <T extends {}>({
  label,
  options,
  valueExtractor,
  descriptionExtractor,
  value,
  onChange,
  disabled,
  sx
}: CustomSelectProps<T>) => {
    return (
    <FormControl fullWidth sx={{ ...sx, mt: 1 }}>
      { disabled ? (
            <Typography variant="h5" gutterBottom sx={{ color: (theme) => theme.palette.text.primary}}>
              Device Type: {value}
          </Typography>
      ) : (
        <div>
          <InputLabel
            id="select-label" // Ensure accessibility by linking the label to the Select via 'id'
            sx={{ color: 'rgba(0, 0, 0, 0.87)' }}  // Apply custom color directly in sx
          >
            {label}
          </InputLabel>
          <Select
            value={value}
            label={label}  // This label is for accessibility and should match the InputLabel text
            labelId="select-label"  // This should match the id of InputLabel for accessibility
            onChange={(e) => onChange(e.target.value as string)}
            sx={{ marginTop: '8px' }}  // Adjust the top margin if needed
            fullWidth
            inputProps={{
            }}
          >
            {options.map((option, index) => (
              <MenuItem key={index} value={valueExtractor(option)}>
                {descriptionExtractor(option) || 'No Description'}
              </MenuItem>
            ))}
          </Select>
        </div>
      )}
    </FormControl>
  );
};

export default CustomSelect;
