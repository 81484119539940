import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Grid } from '@mui/material';
import { OrganizationHierarchyNode } from './types'; // Assuming this type contains the node's details

interface Props {
  node: OrganizationHierarchyNode | null;
  onEdit: () => void;  // This signifies that onEdit is a function that takes no arguments and doesn't return anything
}

const NodeDetails: React.FC<Props> = ({ node, onEdit }) => {
  const [availableLicenses, setAvailableLicenses] = useState(0); // Initial hardcoded

  useEffect(() => {
    // Simulate updating availableLicenses dynamically if needed
    const assignedLicenses = node?.numAssignedLicenses || 0;
    setAvailableLicenses((node?.licensePool || 0) - assignedLicenses);
  }, [node?.licensePool, node?.numAssignedLicenses]);

  if (!node) {
    return (
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h6" color="textSecondary">
            Select a node to see details
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card variant="outlined" > 
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="body1" ><strong>Description:</strong> {node.description || 'No description provided'}</Typography>
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={8} container direction="column" >
              <Typography variant="body1"><strong>License Pool:</strong></Typography>
              <Typography variant="body1"><strong>Assigned Licenses:</strong></Typography>
              <Typography variant="body1"><strong>Available Licenses:</strong></Typography>
            </Grid>
            <Grid item xs={1} container direction="column" alignItems="flex-end">
              <Typography variant="body1">{node.licensePool}</Typography>
              <Typography variant="body1">{node.numAssignedLicenses}</Typography>
              <Typography variant="body1">{availableLicenses}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default NodeDetails;
