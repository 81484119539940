import { useState, useEffect } from "react";
import {
    useTheme,
    Grid,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Checkbox,
    DialogTitle,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import UserService from "../../services/UserService";
import { checkEmail } from "../../pages/Login/ForgotPassword";
import CustomSelect from '../../components/CustomSelect'; // Adjust the import path as needed
import { RoleType, User } from "../../interfaces/user/User";
import { OrgEntry } from "./UserManagement";
import React from 'react';



interface UserAccessDialogProps {
    userData?: User;
    open: boolean;
    dialogMode: string;
    handleClose: () => void;
    userLevels: RoleType []
    organizations: OrgEntry[];
    triggerRefresh: () => void;

}
  
  

const UserMgmtDialog: React.FC<UserAccessDialogProps> = ({ userData, triggerRefresh, open, userLevels, handleClose, organizations, dialogMode }) => {
    //console.log ("UserMgmtDialog", dialogMode, userLevels)
    const { t } = useTranslation();
    const theme = useTheme();
    const [cookies, ,] = useCookies(["access_token"]);
    //const [errorMsg, setErrorMsg] = useState(""); // Show error message dialog
    //const [showOK, setShowOK] = useState(false); // Show OK dialog on user add success
    const [lastName, setLastName] = useState<string>("");
    const [firstName, setFirstName] = useState<string>("");
    const [userName, setUserName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    //const [fetchUsers, setFetchUsers] = useState(0);
    const [saveInProgress, setSaveInProgress] = useState(false);
    const [sendEmailState, setSendEmailState] = useState (false);
    const [selectedUserLevel, setSelectedUserLevel] = useState <RoleType> ()
    const [organization, setOrganization] = useState<string>();
    const [initialPassword, setInitialPassword] = useState<string>('');
    const [isValid, setIsValid] = useState<boolean>(false);
    const [disableRoles, setDisableRoles] = useState (false);
    useEffect (() => {
        if (organizations && organizations?.length === 1) {
            setOrganization (organizations[0].organization_id)
        }
    }, [organizations])


    useEffect (() => {
        console.log ("useEffect:", dialogMode, userData)
        if ((dialogMode !== "edit") || !userData || (userLevels.length === 0)){
            return
        }
        setUserName (userData?.username || '')
        setLastName (userData?.last_name || '')
        setFirstName (userData?.first_name || '')
        setEmail (userData?.email || '')
        setPhone (userData?.phone || '')
        setSelectedUserLevel (userData?.role)
        setOrganization (userData?.organization || '')
        setUserName (userData?.username || '')
        //If the user's role level isn't in the list of roles I can manage, disable that entry.
        const userRoleType = userData?.role?.roletype || ''
        const userLevelFound = userLevels.some(role => role.roletype === userRoleType);
        setDisableRoles (!userLevelFound)
    }, [dialogMode, userData, userLevels])

    const validatePassword = (password: string): boolean => {
        const minLength = 8;
        const hasUpper = /[A-Z]/.test(password);
        const hasLower = /[a-z]/.test(password);
        const hasNumber = /[0-9]/.test(password);
        //const hasSpecial = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    
        return password.length >= minLength && hasUpper && hasLower && hasNumber;// && hasSpecial;
      };
    
    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const newPass = event.target.value;
        setInitialPassword(newPass);
        setIsValid(validatePassword(newPass) || newPass.trim().length === 0);
      };

    const handleSendEmailChange = (event: any) => {
        setSendEmailState (event.target.checked)
    };
    

    const checkRequired = () => {
        if (!checkEmail(email)) {
            return false;
        }
        if (lastName === "" || firstName === "" || userName === "") {
            return false;
        }
        return true;
    };
    
    // Remove characters that are not letters, numbers, hyphens, or underscores
    const santizeInput = (i:string) => {
        return i.replace(/[^a-zA-Z0-9-_]/g, '');
    }

    const handleUserNameChange = (uname: string) => {
        setUserName(santizeInput (uname));
      };
    
    useEffect(() => {
        checkRequired();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email]);

    const handleUserLevelChanged = (level: string) => {
        
        // Find the role entry in the array matching the given level (roletype)
        const roleEntry = userLevels.find(role => role.roletype === level);

        // Check if the roleEntry was found
        if (roleEntry) {
            // Call setUserLevel with the found role entry
            setSelectedUserLevel(roleEntry);
        } else {
            console.error('Role not found for the given level:', level);
        }

      };

      const handleCancelUser = (
        event: React.SyntheticEvent<unknown>,
        reason?: string
    ) => {
        //if (reason !== "backdropClick") {
            handleClose();
        //}
    };

    const handleDeleteUser = async (
        event: React.SyntheticEvent<unknown>,
        reason?: string
        ) => {
            if (reason !== "backdropClick") {
                if (!userData?.username) {
                    return;
                }
                console.log ("Delete user:", userData?.username)

                UserService.deleteUser (userData?.username, cookies.access_token).then ((response: any) => {
                    console.log ("UserService.deleteUser is ", response)
                    triggerRefresh() 
                    handleClose();
                })
            }
        }
    



    const handleSaveUser = async (
        event: React.SyntheticEvent<unknown>,
        reason?: string
    ) => {
        if (reason !== "backdropClick") {
            const userData: User = {
                last_name: lastName,
                first_name: firstName,
                username: userName,
                email: email,
                phone: phone,
                title: "",
                send_invite: sendEmailState,
                organization: organization,
                hierarchy_id: organization,
                roletype: selectedUserLevel?.roletype,
                passwd: initialPassword,
                role: selectedUserLevel
            };

            if (dialogMode === "add") {
                console.log ("Save new user:", userData)
                //setSaveInProgress(true);
                UserService.createUser(userData, cookies.access_token).then((res: any) => {
                        console.log(res);
                        //setSaveInProgress(false);
                        if (res?.status === 200) {
                            //setShowNewUser(false);
                            handleClose();
                            //setShowOK(true);
                            triggerRefresh()
                            return;
                        }
    
                        switch (res.response.status) {
                            case 400: {
                                //console.log ("400")
                                //const errors = res.request.response;
                                //setErrorMsg(errors.password);
                                break;
                            }
                            case 409: {
                                //console.log ("409", res.request.response)
                                //setErrorMsg(t(res.request.response));
                                break;
                            }
    
                            case 404: {
                                //console.log ("404")
                                //setErrorMsg("Not found.");
                                break;
                            }
                        }
                }).catch((error: any) => {
                        console.log("Error createUser:", error);
                        //setErrorMsg(error);
                }).finally(() => {
                    //console.log ("Completed createUser")
                });
            } // new

            if (dialogMode === "edit") {
                console.log ("Modify user:", userData)
                setSaveInProgress(true);
                UserService.modifyUser(userData, cookies.access_token)
                    .then((res: any) => {
                        console.log(res);
                        setSaveInProgress(false);
                        if (res?.status === 200) {
                            handleClose();
                            //setShowOK(true);
                            triggerRefresh()
                            return;
                        }
                    }).catch((error: any) => {
                        console.log("Error modifyUser:", error);
                        //setErrorMsg(error);
                    }).finally(() => {
                        //console.log ("Completed createUser")
                    });
                }
            }
        } 
    
    return (

    <Dialog open={open} fullWidth={true} maxWidth={"sm"}>
                    <DialogTitle sx={{marginBottom: "10px"}}> {dialogMode === "add" ? t("add_user") : t("edit_user")}</DialogTitle>
                    <DialogContent>
                        <Grid item xs={12}>
                            <CustomSelect 
                                label="User Role"
                                options={userLevels}
                                disabled={disableRoles}
                                valueExtractor={(role) => role.roletype}
                                descriptionExtractor={(role) => role.name}
                                value = { selectedUserLevel?.roletype || ""}
                                onChange={handleUserLevelChanged}
                            />

                            <CustomSelect 
                                label="Organization"
                                options={organizations}
                                valueExtractor={(org) => org.organization_id}
                                descriptionExtractor={(org) => org.organization_name}
                                value={organization || ''}
                                onChange={setOrganization}
                            />
   
                            <TextField
                                id="last_name"
                                label={t("lastname")}
                                type="text"
                                variant="outlined"
                                color="primary"
                                fullWidth
                                margin="dense"
                                value={lastName}
                                onChange={(e) => {
                                    setLastName(e.target.value);
                                }}
                                sx={{
                                    input: {
                                        color: theme.palette.text.primary,
                                        background:
                                            theme.palette.background.default,
                                    },
                                    label: {
                                        color: theme.palette.text.primary,
                                    },
                                }}
                            />

                            <TextField
                                id="first_name"
                                label={t("firstname")}
                                type="text"
                                variant="outlined"
                                color="primary"
                                value={firstName}
                                fullWidth
                                margin="dense"
                                onChange={(e) => {
                                    setFirstName(e.target.value);
                                }}
                                sx={{
                                    input: {
                                        color: theme.palette.text.primary,
                                        background:
                                            theme.palette.background.default,
                                    },
                                    label: {
                                        color: theme.palette.text.primary,
                                    },
                                }}
                            />

                            <TextField
                                id="username"
                                label={t("username")}
                                type="text"
                                variant="outlined"
                                color="primary"
                                fullWidth
                                margin="dense"
                                value={userName}
                                disabled = {dialogMode === "edit"}
                                onChange={(e) => {
                                    handleUserNameChange(e.target.value);
                                }}
                                sx={{
                                    input: {
                                        color: theme.palette.text.primary,
                                        background:
                                            theme.palette.background.default,
                                    },
                                    label: {
                                        color: theme.palette.text.primary,
                                    },
                                }}
                            />

                            <TextField
                                id="email"
                                label={t("email_address")}
                                type="email"
                                variant="outlined"
                                color="primary"
                                fullWidth
                                value={email}
                                margin="dense"
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                                sx={{
                                    input: {
                                        color: theme.palette.text.primary,
                                        background:
                                            theme.palette.background.default,
                                    },
                                    label: {
                                        color: theme.palette.text.primary,
                                    },
                                }}
                            />
                            <TextField
                                id="phone"
                                label={t("phone")}
                                type="text"
                                variant="outlined"
                                color="primary"
                                fullWidth
                                margin="dense"
                                value={phone}
                                onChange={(e) => {
                                    setPhone(e.target.value);
                                }}
                                sx={{
                                    input: {
                                        color: theme.palette.text.primary,
                                        background:
                                            theme.palette.background.default,
                                    },
                                    label: {
                                        color: theme.palette.text.primary,
                                    },
                                }}
                            />

                            { dialogMode === "add" && (<div>

                                <TextField
                                    id="password"
                                    label={t("initial_password")}
                                    type="text"
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    margin="dense"
                                    onChange={handlePasswordChange}
                                    sx={{
                                        input: {
                                            color: theme.palette.text.primary,
                                            background:
                                                theme.palette.background.default,
                                        },
                                        label: {
                                            color: theme.palette.text.primary,
                                        },
                                    }}
                                />
                                  {!isValid && initialPassword.length > 0 && <div>Password is not valid.</div>}


                            
                                <FormControlLabel
                                        control={
                                        <Checkbox
                                        style={{ color: theme.palette.primary.main }}
                                        checked={sendEmailState}
                                        onChange={handleSendEmailChange}
                                            />
                                        }
                                        label={t("send_email_invite")}
                                    />
                                </div>)
                            }        
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancelUser}>
                            {t("cancel")}
                        </Button>

                        <Button onClick={handleDeleteUser}>
                            {t("delete")}
                        </Button>

                        <Button
                            disabled={!checkRequired() || saveInProgress}
                            onClick={handleSaveUser}
                        >
                            {t("save")}
                        </Button>
                    </DialogActions>
                </Dialog>

)
}


export default UserMgmtDialog;
