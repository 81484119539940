import {
    Grid,
    Typography,
    useTheme,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Box,

} from "@mui/material";

import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { OrganizationHierarchyNode, PoolDevice  } from './types'; // Define your types in a separate file or within components
import { DeviceType } from "../../pages/SystemManagement/types"
import CustomSelect from "../../components/CustomSelect"
import DeviceService from "../../services/DeviceService"
import MessageAlert from "../../components/MessageAlert";





interface Props {
    initialDevice?: PoolDevice ;
    openState: boolean;
    setOpenState: (show:boolean) => void;
    dialogMode: string;
    parentNode: OrganizationHierarchyNode | null;
    onDeviceAdded: () => void;
    deviceTypes: DeviceType[];
}


const PoolDeviceDialog = ({ initialDevice, openState, setOpenState, dialogMode, parentNode, deviceTypes, onDeviceAdded  } : Props) => {
   //console.log ("PoolDeviceDialog:", initialDevice, parentNode, deviceTypes)

    const theme = useTheme();
    const { t } = useTranslation();
    const [cookies, ,] = useCookies(["access_token"]);
    const [selectedDeviceType, setSelectedDeviceType] = useState<DeviceType> ()
    //const [selectedDeviceTypeChanged, setSelectedDeviceTypeChanged] = useState (false);
    const [deviceId, setDeviceId] = useState <string> ('')
    const [serialNumber, setSerialNumber] = useState <string> ('')
    const [serialnumberChanged, setSerialNumberChanged] = useState (false);
    const [showSaveButton, setShowSaveButton] = useState (false)
    const [confirmDelete, setConfirmDelete] = useState (false);
    const [openMessageDialog, setOpenMessageDialog] = useState(false);
    const [message, setMessage] = useState<string []>(['alert','message']);



    const handleItemChange = (id: string, data: any) => {
        // eslint-disable-next-line
        ///const urlPattern = /^((https?:)?(\/\/)?([\da-z.-]+)?(\.[a-z.]{2,6})?([\/\w.-]*)*\/?)?$/;
        // eslint-disable-next-line
        //const addressPattern = /^(https?:)?(\/\/)?([\da-z.-]+)?(\.[a-z.]{2,6})?(:\d{0,5})?([\/\w .-]*)*\/?$/;

        //const stringPattern = /^[a-zA-Z0-9_-]*$/;
        switch (id) {

            case "serialNumber" : {
                const val: string = String (data).trim();

                setSerialNumberChanged (val !== initialDevice?.device_serialnumber)
                setSerialNumber (val);
                setDeviceId (val)
                break;
            }


        } // switch

    } // handleItemChange

    const handleDeviceTypeChanged = (dtype: string) => {
        
        // Find the role entry in the array matching the given level (roletype)
        //const deviceEntry = parentNode?.devicetypes.find(item => item.model === dtype);
        const deviceEntry = deviceTypes.find(item => item.model === dtype);

        // Check if the roleEntry was found
        if (deviceEntry) {
            // Call setUserLevel with the found role entry
            //setSelectedDeviceTypeChanged (dtype !== initialDevice?.deviceType)
            setSelectedDeviceType(deviceEntry);
        } else {
            console.error('Device Type not found for :', dtype);
        }

    };

      
    useEffect (() => {
        if (!initialDevice) {
            return;
        }
        // Function to find a device by device_id
        function findDeviceTypeById(model: string): DeviceType | undefined {
            return parentNode?.devicetypes.find(dtype => dtype.model === model);
        }

        setDeviceId     (initialDevice.device_id);
        setSerialNumber (initialDevice.device_serialnumber)
        const devtype = findDeviceTypeById (initialDevice.deviceType)
        setSelectedDeviceType (devtype)

    }, [initialDevice, parentNode?.devicetypes])
    
    useEffect (() => {
        var showSave = false;
        switch (dialogMode) {
            case "edit" : {
                showSave = false
                break;
            }

            case "add" : {
                showSave = serialNumber !== '' && selectedDeviceType !== undefined;
                                
                break;
            }
        }
        setShowSaveButton (showSave)

    }, [dialogMode, serialNumber, selectedDeviceType])


    const deleteDevice = () => {
        setConfirmDelete (true)
    };

    const cancelDelete = () => {
        setConfirmDelete (false)
    };

    const yesDelete = () => {
        //console.log ("yesDelete: device_id=", deviceId, "parentNode=",parentNode)

        //OrgService.deleteCustomer ( cookies.access_token, organization_id, parent_id)
        DeviceService.deletePoolDevice ( deviceId, cookies.access_token)
        .then((res: any) => {
            //onNodeDeleted (organization_id, parentNode.id)
            //triggerRefresh ();
            onDeviceAdded ()
        })
        .catch((err: any) => {
            console.log(err);
        }).finally (() => {
            setConfirmDelete (false)
            handleClose();
        });

    };
    const handleClose = () => {

        setOpenState (false);
        setSerialNumber('')
        setSerialNumberChanged (false)
        setSelectedDeviceType(undefined)
        //setSelectedDeviceTypeChanged (false);
    } // handleClose



    /*
            device_id     = request.data.get("device_id")
        device_model  = request.data.get ("device_model")
        serial_number = request.data.get ("serial_number")
        valid         = request.data.get ("valid", True)
        parent_name   = request.data.get ("parentNode")

        */
    const handleSaveChanges = () => {
        console.log ("handleSaveChanges", dialogMode)
        if (dialogMode === "add") {
            // POST a new device
            const data = {
                "device_id"      : deviceId,
                "device_model"   : selectedDeviceType?.model || '',
                "serial_number"  : serialNumber,
                "parentNode"     : parentNode?.node_name || '',
            }
            //console.log ("edit parameters:", data)
            DeviceService.addPoolDevice (data, cookies.access_token)
                .then((res: any) => {
                    console.log("result:", res);
                    if (res.status === 409) {
                        setMessage (["Unable to add device", "Device already exists"])
                        setOpenMessageDialog (true)    
                    }
                    if (res.status === 200) {
                        setShowSaveButton (false);
                        onDeviceAdded ()
                        handleClose();
                    }
                })
                .catch((err: any) => {
                    console.log(err);
                }).finally (() => {
                    //onNodeAdded (orgId)
                    //triggerRefresh ();
                });

            handleClose();
            return;
        } // if "add"






        if (dialogMode === "edit") {
            // PATCH 
        }

    } // handleSaveChanges
  
    const handleCloseMessageDialog = () => setOpenMessageDialog(false);
  
    return (
    <>
        <Dialog open={confirmDelete} >
            <DialogTitle> {t ("confirm_organization_delete") } </DialogTitle>
            <DialogContent>
                <Typography>{t("delete_organization_notification")}</Typography>
            </DialogContent>
            <DialogActions>
                <Grid container direction="row" justifyContent="space-between"  alignItems="center" >
                    <Button variant="outlined" onClick={cancelDelete}> { t("cancel") }</Button>
                    <Button variant="contained" onClick={yesDelete} color="warning">  { t("yes_delete") }</Button>
                </Grid>
            </DialogActions>
        </Dialog>


        <MessageAlert
            open={openMessageDialog}
            message={message}
            onClose={handleCloseMessageDialog}
        />

          <Dialog  open = {openState}   fullWidth maxWidth="md" >
            <DialogTitle>
                <Grid container alignItems="center">
                    <Grid item xs>
                        <Box>{t("add_pool_device")}</Box>
                    </Grid>
                </Grid>
            </DialogTitle>

            <DialogContent>
                <Grid container item pt={3} mb={1}  spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            label={t("serialnumber")}
                            id="serialnumber"
                            type=""
                            disabled={dialogMode === "edit"}
                            value={serialNumber}
                            onChange={(e) => handleItemChange("serialNumber", e.target.value)}
                            InputLabelProps={{ shrink: true }}   
                            placeholder = "Enter description"                 
                            sx={{
                                input: { color: theme.palette.text.primary, 
                                        background: !serialnumberChanged ? theme.palette.background.default : theme.palette.warning.light},                                    
                                label: { color: theme.palette.text.primary },
                            }}
                        />

                    </Grid>
                    <Grid item xs={6}>
                        <CustomSelect
                                label="Device Type"
                                options={deviceTypes}
                                valueExtractor={(role) => role.model}
                                descriptionExtractor={(role) => role.model}
                                value = { selectedDeviceType?.model || ""}
                                onChange={handleDeviceTypeChanged}
                                disabled = {dialogMode === "edit"}
                        />

                    </Grid>
                    </Grid>
            


            </DialogContent>

            <DialogActions>
                <Grid container direction="row" justifyContent="space-between"  alignItems="center" >
                    <Button variant="outlined" onClick={handleClose}> { t("close") }</Button>
                    {showSaveButton && (
								<Button onClick={handleSaveChanges} variant="outlined"
                                        sx={{background: theme.palette.warning.light}}
                                >
									{t("save_changes")}
								</Button>
							)}
                    { dialogMode === "edit" && 
                        <Button variant="outlined" onClick={deleteDevice} >  { t("delete_device") }</Button>
                    }

                </Grid>
            </DialogActions>

        </Dialog>

    </>
    )
}

export default PoolDeviceDialog;