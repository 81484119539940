import {
	Typography,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@mui/material";
import { Loading } from "../../components/Loading";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useState, useEffect, useRef, useReducer } from "react";
import { Grid } from "@mui/material";
import { useCookies } from "react-cookie";
import DeviceService from "../../services/DeviceService";
import { ManualParmInput } from "../device/ManualParmInput";
import { AlarmProfileSelectMemo } from "../../pages/Home/Device/AlarmProfileSelect";
import { useTranslation } from "react-i18next";
import { TimelineBoxMemo } from "./TimelineBox";
import { ScoringBox } from "./ScoringBox";
import { CareSummaryProps } from "./interfaces";
import { almProfileEntry } from "./interfaces";
import useSentioSocket from "../../hooks/useSentioSocket";
import { GranularitySelector } from "./GranularitySelector";
import {SelectParameters} from "../device/SelectParameters";
import { DialogCaseId } from "../DialogCaseId";

export const GRAPH_MARGIN = 64;
export const graphUnitHeightPx = 18;
export const MAX_REPORT_DURATION_SEC = 48 * 60 * 60; // 48 hours
const MIN_REPORT_DURATION_SEC = 1 * 60 * 30; // 30 minutes

const scoringColors = {
	minor: "#FFFF0055",
	major: "#F39C1255",
	critical: "#FF000055",
};

/*
    step: interval between steps on the scale
    max: max number of "step" intervals : max plot value = step * max
    min: min number of "step" intervals : min plot value = step * min
 */
const unitSteps: { [key: string]: {} } = {
	RR: { step: 5, max: 45, min: 0 },
	SPO2: { step: 2, max: 102, min: 86 },
	NIBP_Systole: { step: 10, max: 240, min: 40 },
	HR: { step: 10, max: 150, min: 30 },
	EtCO2: { step: 10, max: 80, min: 0 },
	ACVPU: { step: 1, max: 5, min: 0 },
	RespDistress: { step: 1, max: 4, min: 0 },
	Temp1: { step: 1, max: 44, min: 30 },
	Act:   { step: 1, max: 10, min: 0}
};


export const CareSummaryInfo = ({
	device,
	initialAlmProfileDef,
	parmHistory,
	alarmProp,
}: CareSummaryProps) => {
	//console.log ("CareSummaryInfo:", device);//, initialAlmProfileDef, alarmProp, parmHistory)
	//console.log ("initialAlmProfileDef:", initialAlmProfileDef);//, initialAlmProfileDef, alarmProp, parmHistory)
	//console.log ("possible alarm profiles:", alarmProp);
	//console.log ("Device def:", JSON.parse (device.device_def))
	const [, forceUpdate] = useReducer(x => x + 1, 0);
  
	const { t } = useTranslation();
	const [cookies, ,] = useCookies(["access_token"]);
	const [activeAlmProfile, setActiveAlmProfile] =	useState<almProfileEntry>(initialAlmProfileDef);

	const [openNewChartDialog, setOpenNewChartDialog] = useState(false);
	const [openExportSnackbar, setOpenExportSnackbar] = useState(false);
	const [openCaseDialog, setOpenCaseDialog] = useState(false);
	const [openExportDialog, setOpenExportDialog] = useState(false);
	const [parmInterval, setParmInterval] = useState(10);
	const [ACVPU_val, setACVPU_val] = useState(
		device.dynamic_data?.ACVPU?.value
			? device.dynamic_data?.ACVPU?.value
			: ""
	);
	const [RespDistress_val, setRespDistress_val] = useState(
		device.dynamic_data?.RespDistress?.value
			? device.dynamic_data?.RespDistress?.value
			: ""
	);
	const [chart_max_ts, setChart_max_ts] = useState<number>(0);
	const [chart_min_ts, setChart_min_ts] = useState<number>(NaN);
	const [dataPacket, setDataPacket] = useState<any>(null);
	const currentTs = useRef(0);
	const exportEnabled = useRef(false);
	const max_timestamp = useRef<number>(NaN);
	const min_timestamp = useRef<number>(NaN);

	const lookup_almProfile = (code: string) => {
		for (var i = 0; i < activeAlmProfile?.alarms?.length; i++) {
			const p = activeAlmProfile.alarms[i];
			if (p.code === code) {
				p.unitStep = unitSteps[code];
				return p;
			}
		}
		//console.log ("Return alm profile NULL for ", code, activeAlmProfile)
		return null;
	}; // lookup_almProfile

	// Set up streaming of live vital signs
	useSentioSocket({ device, setDataPacket, trendData:true });

	const RR_history = useRef(parmHistory.data["Resp"]);
	const O2_history = useRef(parmHistory.data["SpO2"]);
	const Temp1_history = useRef(parmHistory.data["Temp1"]);
	const ts_history = useRef(parmHistory.data["timestamps"]);
	const NIBP_history = useRef(parmHistory.data["NIBP_Systole"]);
	const HR_history = useRef(parmHistory.data["HR"]);
	const EtCO2_history = useRef(parmHistory.data["EtCO2"]);
	const ACVPU_history = useRef(parmHistory.data["ACVPU"] || []);
	const RespDistress_history = useRef(parmHistory.data["RespDistress"] || []);
	const Act_history = useRef(parmHistory.data["Act"] || []);
	const RR_alm = useRef(lookup_almProfile("RR"));
	const O2_alm = useRef(lookup_almProfile("SPO2"));
	const Temp1_alm = useRef(lookup_almProfile("Temp1"));
	const NIBP_alm = useRef(lookup_almProfile("NIBP_Systole"));
	const HR_alm = useRef(lookup_almProfile("HR"));
	const EtCO2_alm = useRef(lookup_almProfile("EtCO2"));
	const ACVPU_alm = useRef(lookup_almProfile("ACVPU"));
	const RespDistress_alm = useRef(lookup_almProfile("RespDistress"));
	const Act_alm = useRef(lookup_almProfile("Act"));
	const [noData, setNoData] = useState(false);
	var ALL_PARAMS:string[] = []

	ALL_PARAMS = []
	for (var i = 0; i < activeAlmProfile.alarms.length; i++) {

		// don't allow IBP
		if (activeAlmProfile.alarms[i].code !== "IBP1_Systole")
			ALL_PARAMS.push (activeAlmProfile.alarms[i].code)
	}

	useEffect(() => {
		console.log("Parmhistory trigger", parmHistory.data);

		if (parmHistory.data.timestamps.length === 0) {
			min_timestamp.current = Date.now();
			max_timestamp.current =
				min_timestamp.current + MIN_REPORT_DURATION_SEC * 1000;
			console.log("No data to display. Set noData to true");
			setNoData(true);
		}

		if (parmHistory?.data?.timestamps) {
			if (parmHistory.data.timestamps.length > 0) {
				//const min_entry:string = parmHistory.data.timestamps[0];
				//const min_date: Date = new Date (min_entry);
				min_timestamp.current = Math.floor(
					parmHistory.data.timestamps[0] * 1000
				); // Convert to milliseconds

				const last_idx = parmHistory.data.timestamps.length - 1;

				//const max_entry:string = parmHistory.data.timestamps[last_idx];
				//const max_date: Date = new Date (max_entry);
				max_timestamp.current = Math.floor(
					parmHistory.data.timestamps[last_idx] * 1000
				); // Convert to milliseconds
			}
		}
		
		setChart_max_ts(max_timestamp.current);
		setChart_min_ts(min_timestamp.current);
	
	}, [parmHistory]);

	useEffect(() => {
		RR_alm.current = lookup_almProfile("RR");
		O2_alm.current = lookup_almProfile("SPO2");
		Temp1_alm.current = lookup_almProfile("Temp1");
		NIBP_alm.current = lookup_almProfile("NIBP_Systole");
		HR_alm.current = lookup_almProfile("HR");
		EtCO2_alm.current = lookup_almProfile("EtCO2");
		ACVPU_alm.current = lookup_almProfile("ACVPU");
		RespDistress_alm.current = lookup_almProfile("RespDistress");
		Act_alm.current = lookup_almProfile("Act");

		//setChart_min_ts (chart_min_ts)
		forceUpdate ();
		//max_timestamp.current = Math.max (min_timestamp.current, max_timestamp.current)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeAlmProfile]);

	const [selectedAlarmProfileKey, setSelectedAlarmProfileKey] =
		useState<string>(device.alarm_profile_key);

	//console.log ("Vitals:", vitals)
	useEffect(() => {
		if (dataPacket === null || Object.keys(dataPacket).length === 0) {
			return;
		}
		currentTs.current = Date.now();
		if (noData) {
			setNoData(false);
			console.log("Got datapacket - set noData to false");
		}

		// First add the timestamp to the history array
		const packet_date = new Date(dataPacket.timestamp);
		const packet_ts = packet_date.getTime() / 1000; // Needs to be in seconds to be consistent with parm history.;
		ts_history.current = [...ts_history.current, packet_ts];
		max_timestamp.current = packet_ts;

		// Add the various parameters, if they exist.
		O2_history.current = [
			...O2_history.current, dataPacket?.SpO2 ? dataPacket?.SpO2 : "--",
		];
		RR_history.current = [
			...RR_history.current, dataPacket?.Resp ? dataPacket.Resp : "--",
		];
		NIBP_history.current = [
			...NIBP_history.current, dataPacket?.NIBP ? dataPacket.NIBP : "--",
		];
		HR_history.current = [
			...HR_history.current, dataPacket?.HR ? dataPacket.HR : "--",
		];
		Temp1_history.current = [
			...Temp1_history.current, dataPacket?.Temp ? dataPacket?.Temp : "--",
		];
		EtCO2_history.current = [
			...EtCO2_history.current, dataPacket?.EtCO2 ? dataPacket.EtCO2 : "--",
		];
		if (dataPacket?.ACVPU) {
			ACVPU_history.current = [
				...ACVPU_history.current, dataPacket?.ACVPU ? dataPacket.ACVPU : "--",
			];
		}
		if (dataPacket?.RespDistress) {
			RespDistress_history.current = [
				...RespDistress_history.current, dataPacket?.RespDistress ? dataPacket.RespDistress	: "--",
			];
		}

		setACVPU_val(dataPacket.ACVPU?.value ? dataPacket.ACVPU?.value : "");
		setRespDistress_val(
			dataPacket.RespDistress?.value ? dataPacket.RespDistress?.value : ""
		);
		Act_history.current = [
			...Act_history.current, dataPacket?.Act ? dataPacket?.Act : "--",
		];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataPacket]);

	const current_ts = currentTs.current; //Date.now()

	if (isNaN(max_timestamp.current) || isNaN(min_timestamp.current)) {
		exportEnabled.current = false;
	} else {
		exportEnabled.current = true;
	}

	// Check if we should ajust the lower timestamp. That should only happen once.
	if (!isNaN(min_timestamp.current)) {
		if (isNaN(chart_min_ts) || min_timestamp.current < chart_min_ts) {
			setChart_min_ts(min_timestamp.current);
			//console.log ("Initialize min:", min_timestamp.current, max_timestamp.current - min_timestamp.current)
		}
	}

	// Check the upper timestamp. Here we need to do a bit more work because the upper timestamp keeps incrementing.
	// At some point it will increment off the screen, and we need to re-adjust the chart to new boundaries.

	// Check if we just went off the edge. It will happen on the first datagram, and then every N minutes according to the code below.
	const CHART_RIGHT_HORIZON = 60 * 1000; // One minute "margin" to the right, i.e. rescale when there is one minute left of the drawing area.
	if (
		!isNaN(min_timestamp.current) &&
		!isNaN(max_timestamp.current) &&
		max_timestamp.current >= chart_max_ts - CHART_RIGHT_HORIZON
	) {
		const CHART_EXPANSION_AMOUNT = 15 * 60 * 1000; // Increment the chart every 15 minutes
		setChart_max_ts(max_timestamp.current + CHART_EXPANSION_AMOUNT);
	}

	const handleStartNew = () => {
		setOpenNewChartDialog(true);
	};
	const handleSetCaseID = () => {
		setOpenCaseDialog(true);
	};

	const handleStartExport = () => {
		setOpenExportDialog(true);
	};
	const handleCancelExport = () => {
		setOpenExportDialog(false);
	};

	const handleCSVExport = () => {
		setOpenExportDialog(false);
		handleFileExport("csv");
	};

	const handlePDFExport = () => {
		setOpenExportDialog(false);
		handleFileExport("pdf");
	};

	const handleJSONExport = () => {
		setOpenExportDialog(false);
		handleFileExport("json");
	};

	const handleXMLExport = () => {
		setOpenExportDialog(false);
		handleFileExport("xml");
	};

	const handleFileExport = (export_type: string) => {
		const min_time = new Date(chart_min_ts).toISOString();
		const max_time = new Date(chart_max_ts).toISOString();
		const tzoffset = new Date(chart_min_ts).getTimezoneOffset();
		const almProfileHeader = activeAlmProfile.headline;
		const data = {
			device_id: device.device_id,
			device_type: device.device_type,
			device_model: device.device_model,
			chart_type: almProfileHeader, // must be the headline
			patientinfo: dataPacket !== null ? dataPacket.patientInfo : null,
			start_time: min_time,
			end_time: max_time,
			tzoffset: tzoffset,
			case_id: device.case_id,
			file_type: export_type,
			interval: parmInterval,
			parameters: selectedParameters
		};

		setOpenExportSnackbar(true);
		console.log ("EXPORT:", data)
		DeviceService.exportToFile(data, cookies.access_token).then(
			(res: any) => {
				var fileOfBlob = new File([res.data], "chart.tmp");

				const fileURL = window.URL.createObjectURL(fileOfBlob);
				// Setting various property values
				let alink = document.createElement("a");
				alink.href = fileURL;

				const d = new Date();
				const mm =
					d.getMonth() < 10 ? "0" + d.getMonth() : d.getMonth();
				const dd = d.getDate() < 10 ? "0" + d.getDay() : d.getDay();
				const HH =
					d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
				const MM =
					d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();

				const ftime =
					d.getFullYear() + "-" + mm + "-" + dd + "_" + HH + "." + MM;

				alink.download =
					device.device_id + "_" + ftime + "." + export_type;
				alink.click();
				setOpenExportSnackbar(false);
			}
		);
	};

	const handleCloseYes = () => {
		// Send msg to back-end to erase hotstorage data for this device.
		const data = { device_id: device.device_id };
		DeviceService.eraseHotstorage(data, cookies.access_token).then(
			(res: any) => {
				window.location.reload();
			}
		);
		setOpenNewChartDialog(false);
	};

	const handleCloseNo = () => {
		setOpenNewChartDialog(false);
	};

	const isPharlap = localStorage.getItem("SYSTEM_TYPE") === "PHARLAP";
	var savedParams = JSON.parse(
		// @ts-ignore
		localStorage.getItem("caresummary-" + device.device_id)
	);

	// Remove any parameters that are not in the ALL_PARAMETERS list
	if (savedParams) {
		for (var idx= 0; idx < savedParams.length; idx++) {
			const key = savedParams[idx]
	
			if (!ALL_PARAMS.includes (key)) {
				const index = savedParams.indexOf(key, 0);
				if (index > -1) {
					savedParams.splice(index, 1);
				}
			}
		}	
	}

	//console.log("savedParams", savedParams);
	//var ALL_PARAMS = Object.keys (deviceDef.parameters)
	//console.log ("alm profile:", activeAlmProfile)
	//console.log ("ALL_PARAMS:", ALL_PARAMS)
	
	const LIMITED_PARAMS = ["HR", "O2", "NIBP", "Resp"];
	const [selectedParameters, setSelectedParameters] = useState<string[]>(
		savedParams ?? (isPharlap ? LIMITED_PARAMS : ALL_PARAMS)
	);

	if (isNaN(chart_min_ts) || isNaN(chart_max_ts)) {
		//console.log ("Chart min")
		return <Loading />;
	}

	//console.log ("CareSummaryInfo:", chart_min_ts, chart_max_ts + "  " +  Math.floor ((chart_max_ts - chart_min_ts)/1000));//, initialAlmProfileDef, alarmProp, parmHistory)
	//console.log ("Selected parms:", selectedParameters)
	return (
		<Grid container>
			<Grid container direction="row" mb={1}>
				<Grid item xs={2} container alignItems={"center"}>
					<SelectParameters
						deviceId={device.device_id}
						selectableParameters={
							isPharlap ? LIMITED_PARAMS : ALL_PARAMS
						}
						selectedParameters={selectedParameters}
						setSelectedParameters={setSelectedParameters}
						maxParameters={
							isPharlap
								? LIMITED_PARAMS.length
								: ALL_PARAMS.length
						}
						type="caresummary"
						width="95%"
						margin={0}
					/>
				</Grid>
				<Grid item xs={2}>
					<Button variant="contained" onClick={handleStartNew}>
						{t("START NEW CHART")}
					</Button>
				</Grid>
				<Grid item xs={2}>
					<Button
						variant="contained"
						onClick={handleStartExport}
						disabled={openExportSnackbar || !exportEnabled.current}
					>
						{t("EXPORT CHART")}
					</Button>
				</Grid>
				<Grid item xs={3}>
					<ManualParmInput
						device={device}
						ACVPU_val={ACVPU_val}
						RespDistress_val={RespDistress_val}
					/>
				</Grid>
				<Grid item xs={2}>
					<Button variant="contained" onClick={handleSetCaseID}>
						{t("set_case_id")}
					</Button>
				</Grid>
			</Grid>
			<Grid container direction="row">
				<Grid item xs={2}></Grid>
				<Grid item xs={2}>
					Device: {device.device_id}
				</Grid>
				<Grid item xs={3}>
					Patient Name: {dataPacket?.patientInfo?.last_name},
					{dataPacket?.patientInfo?.first_name}
				</Grid>
				<Grid item xs={1}>
					Age: {dataPacket?.patientInfo?.age}
				</Grid>
				<Grid item xs={1}>
					Gender: {dataPacket?.patientInfo?.gender}
				</Grid>
				<Grid item xs={1}>
					Bed: {dataPacket?.patientInfo?.bed_no}
				</Grid>
				<Grid item xs={2}>
					Case ID: {device.case_id}
				</Grid>
			</Grid>
			<Grid container sx={{ marginBottom: 3 }}>
				<TimelineBoxMemo min_ts={chart_min_ts} max_ts={chart_max_ts}>
					<AlarmProfileSelectMemo
						profileList={alarmProp.alarm_profiles}
						profileDefs={alarmProp.alarm_profile_defs}
						selectedProfile={selectedAlarmProfileKey}
						setProfileDef={setActiveAlmProfile}        // the profile itself
						setProfileSelect={setSelectedAlarmProfileKey} // the key
						title={t("Chart Type")}
					/>
				</TimelineBoxMemo>
				{noData && (
					<Typography variant="h4" color="text.primary">
						{t("no_data_to_display")}
					</Typography>
				)}
				{selectedParameters.includes("SPO2") && (
					<ScoringBox
						parm={O2_history.current}
						ranges={O2_alm.current}
						timestamps={ts_history.current}
						colors={scoringColors}
						min_ts={chart_min_ts}
						max_ts={chart_max_ts}
						current_ts={current_ts}
					/>
				)}
				{selectedParameters.includes("NIBP_Systole") && (
					<ScoringBox
						parm={NIBP_history.current}
						ranges={NIBP_alm.current}
						timestamps={ts_history.current}
						colors={scoringColors}
						min_ts={chart_min_ts}
						max_ts={chart_max_ts}
						current_ts={current_ts}
					/>
				)}
				{selectedParameters.includes("HR") && (
					<ScoringBox
						parm={HR_history.current}
						ranges={HR_alm.current}
						timestamps={ts_history.current}
						colors={scoringColors}
						min_ts={chart_min_ts}
						max_ts={chart_max_ts}
						current_ts={current_ts}
					/>
				)}
				{selectedParameters.includes("RR") && (
					<ScoringBox
						parm={RR_history.current}
						ranges={RR_alm.current}
						timestamps={ts_history.current}
						colors={scoringColors}
						min_ts={chart_min_ts}
						max_ts={chart_max_ts}
						current_ts={current_ts}
					/>
				)}
				{selectedParameters.includes("Temp1") && (
					<ScoringBox
						parm={Temp1_history.current}
						ranges={Temp1_alm.current}
						timestamps={ts_history.current}
						colors={scoringColors}
						min_ts={chart_min_ts}
						max_ts={chart_max_ts}
						current_ts={current_ts}
					/>
				)}
				{!isPharlap && (
					<>
						{selectedParameters.includes("EtCO2") && (
							<ScoringBox
								parm={EtCO2_history.current}
								ranges={EtCO2_alm.current}
								timestamps={ts_history.current}
								colors={scoringColors}
								min_ts={chart_min_ts}
								max_ts={chart_max_ts}
								current_ts={current_ts}
							/>
						)}
						{selectedParameters.includes("ACVPU") && (
							<ScoringBox
								parm={ACVPU_history.current}
								ranges={ACVPU_alm.current}
								timestamps={ts_history.current}
								colors={scoringColors}
								min_ts={chart_min_ts}
								max_ts={chart_max_ts}
								current_ts={current_ts}
							/>
						)}
						{selectedParameters.includes("RespDistress") && (
							<ScoringBox
								parm={RespDistress_history.current}
								ranges={RespDistress_alm.current}
								timestamps={ts_history.current}
								colors={scoringColors}
								min_ts={chart_min_ts}
								max_ts={chart_max_ts}
								current_ts={current_ts}
							/>
						)}
						{selectedParameters.includes("Act") && (
							<ScoringBox
								parm={Act_history.current}
								ranges={Act_alm.current}
								timestamps={ts_history.current}
								colors={scoringColors}
								min_ts={chart_min_ts}
								max_ts={chart_max_ts}
								current_ts={current_ts}
							/>
						)}

					</>
				)}
			</Grid>
			{/* -------------------------------- DIALOGS -------------------------------- */}
			<Dialog open={openNewChartDialog}>
				<DialogTitle> {t("START NEW CHART")} </DialogTitle>
				<DialogContent>
					<Typography ml={1}>
						{t("confirm_start_new_chart")}
					</Typography>
					<Typography ml={1}>
						{t("this_will_erase_all_history_data")}
					</Typography>
				</DialogContent>

				<DialogActions>
					<Button onClick={handleCloseNo}> {t("no")}</Button>
					<Button onClick={handleCloseYes}> {t("yes")}</Button>
				</DialogActions>
			</Dialog>{" "}
			{/* Start New Chart */}
			<Snackbar
				open={openExportSnackbar}
				anchorOrigin={{ horizontal: "center", vertical: "top" }}
			>
				<Alert sx={{ padding: 4 }} severity="success">
					{t("GENERATING FILE")}
				</Alert>
			</Snackbar>
			<DialogCaseId
				device={device}
				openDialog={openCaseDialog}
				setOpenDialog={setOpenCaseDialog}
			/>
			{/* Change Case ID dialog */}
			<Dialog open={openExportDialog}>
				<DialogTitle> {t("export_chart_to_file")} </DialogTitle>
				<DialogContent>
					{ false && <GranularitySelector
						setParmInterval={setParmInterval}
						defaultval={device.parameter_granularity}
						title={t("enter_parameter_interval")}
					/>}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCancelExport}> {t("cancel")}</Button>
					<Button variant="contained" onClick={handlePDFExport}>
						{t("Export to PDF")}
					</Button>
					<Button variant="contained" onClick={handleCSVExport}>
						{t("Export to CSV")}
					</Button>
					<Button variant="contained" onClick={handleJSONExport}>
						{t("Export to JSON")}
					</Button>
					<Button variant="contained" onClick={handleXMLExport}>
						{t("Export to XML")}
					</Button>
				</DialogActions>
			</Dialog>{" "}
			{/* Start New Chart */}
		</Grid>
	);
};

/*
      



*/
