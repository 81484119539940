import React, { useContext, useState } from "react";
//import { isBrowser } from "react-device-detect";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import { useHistory } from "react-router-dom";
import logo from "../../assets/images/infiniwell_PMS_logo-symbol.png";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import {
	FormControlLabel,
	FormGroup,
	Grid,
	useTheme,
} from "@mui/material";

import { useTranslation } from "react-i18next";
// import { GetFileFromServer } from "../../utils/functions/GetFileFromServer";
import { UserContext } from "../../context/UserContext";
import { SelectLanguage } from "../wrapper/SelectLanguage";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { ThemeContext } from "../../context/ThemeContext";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import TranslateIcon from "@mui/icons-material/Translate";
import LogoutIcon from "@mui/icons-material/Logout";
import DevicesOtherIcon from "@mui/icons-material/DevicesOther";
import SettingsIcon from '@mui/icons-material/Settings';
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import InfoIcon from '@mui/icons-material/Info';
import HomeIcon from "@mui/icons-material/Home";
import { isSentio } from "../../utils/constants/constants";
import { User } from "../../interfaces/user/User";
import AboutDialog from "../../components/AboutDialog";
import { useAuth } from '../../services/auth';

const CMSNavbar = () => {
	const [openDialog, setOpenDialog] = React.useState(false);
	const { handleLogout } = useAuth();

	const handleOpenDialog = () => {
	  setOpenDialog(true);
	};
	
	const handleCloseDialog = () => {
	  setOpenDialog(false);
	};
		
	const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
		null
	);
	const [anchorElHome, setAnchorElHome] = React.useState<null | HTMLElement>(
		null
	);

	const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};
	const handleOpenHomeMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElHome(event.currentTarget);
	};

	const handleCloseHomeMenu = () => {
		setAnchorElHome(null);
	};
	const { t } = useTranslation();
	const history = useHistory();
	const theme = useTheme();
	const userCtx = useContext(UserContext);
	const themeCtx = useContext(ThemeContext);
	const [user] = useState<User | null>(
		// @ts-ignore
		JSON.parse(localStorage.getItem("user")) || null
	);



	const colorMode = () => {
		themeCtx.toggleColorMode();
		const themeTo = theme.palette.mode === "light" ? "dark" : "light";
		localStorage.setItem("mode", themeTo);
	};
	/*
	const [openQRdialog, setOpenQRdialog] = useState(false);
	const handleCloseQR = () => {
		setOpenQRdialog(false);
	};


	const QRdialog = () => {
		return (
			<div>
				<Button
				disabled
					variant="text"
					sx={{
						color: theme.palette.text.disabled,
					}}
				>
					{userCtx?.user?.first_name + " " + userCtx?.user?.last_name}
				</Button>
				<Dialog open={openQRdialog} onClose={handleCloseQR}>
					<DialogTitle>{t("login_with_qr")}</DialogTitle>
					<DialogContent>
						<QRCode value={userCtx!.user!.qr_data!} />
					</DialogContent>
					<DialogActions>
						<Button onClick={handleCloseQR}>{t("close")}</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	};
	*/
	return (
		<AppBar
			position="sticky"
			sx={{
				backgroundColor: theme.palette.background.default,
			}}
		>
			<Container maxWidth="xl">
				<Toolbar disableGutters>
					<Grid
						container
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						>
						{/* Menu/icon container */}
						<Grid container item xs={4}>

							{/* Home Icon */}
							<Tooltip
								title={
									<Typography>
										{t("open_settings")}
									</Typography>
								}
							>
								<IconButton
									onClick={handleOpenHomeMenu}
									sx={{ p: 0 }}
								>
									<img
										className="logo"
										alt="Infiniwell-logo"
										src={logo}
										width="55"
										height="30"
									/>

									{anchorElHome ? (
										<ArrowDropUpIcon />
									) : (
										<ArrowDropDownIcon />
									)}
								</IconButton>
							</Tooltip>

							{/* Left-hand side menu*/}
							<Menu
								sx={{ mt: "45px" }}
								id="menu-appbar"
								anchorEl={anchorElHome}
								anchorOrigin={{
									vertical: "top",
									horizontal: "left",
								}}
								keepMounted
								transformOrigin={{
									vertical: "top",
									horizontal: "left",
								}}
								open={Boolean(anchorElHome)}
								onClose={handleCloseHomeMenu}
								>
								
								  <MenuItem
									onClick={() => {
										setAnchorElHome(null);
										history.push("/");
									}}
									sx={{
										paddingY: "15px",
										display: "flex",
										justifyContent: "space-between",
									}}
								  >
										<HomeIcon />
										<Typography>{t("Dashboard")}</Typography>
									</MenuItem>

								{userCtx?.user?.is_admin && (
									<MenuItem
										onClick={() => {
											setAnchorElHome(null);
											setAnchorElUser(null);
											history.push("/usermgmt");
										}}
										sx={{
											paddingY: "15px",
											display: "flex",
											justifyContent: "space-between",
										}}
									>
										<ManageAccountsIcon />
										<Typography>
											{t("user_management")}
										</Typography>
									</MenuItem>
								)}
								{ user?.is_admin && 
									<MenuItem
										onClick={() => {
											setAnchorElHome(null);
											history.push("/accessmgmt");
										}}
										sx={{
											width: "250px",
											paddingY: "15px",
											display: "flex",
											justifyContent: "space-between",
										}}
									>
										{theme.palette.mode === "dark" ? (
											<DevicesOtherIcon />
										) : (
											<DevicesOtherIcon />
										)}

										<Typography>
											{t("access_management")}
										</Typography>
									</MenuItem>
								}

								{ user?.is_admin && 
									<MenuItem
										onClick={() => {
											setAnchorElHome(null);
											history.push("/devicemgmt");
										}}
										sx={{
											width: "250px",
											paddingY: "15px",
											display: "flex",
											justifyContent: "space-between",
										}}
									>
										{theme.palette.mode === "dark" ? (
											<DevicesOtherIcon />
										) : (
											<DevicesOtherIcon />
										)}

										<Typography>
											{t("device_management")}
										</Typography>
									</MenuItem>
								}

								{isSentio && user?.is_admin && (
									<MenuItem
										onClick={() => {
											setAnchorElHome(null);
											history.push("/vicumgmt");
										}}
										sx={{
											paddingY: "15px",
											display: "flex",
											justifyContent: "space-between",
										}}
									>
										<WorkspacesIcon />

										<Typography>
											{t("monitor_group")}
										</Typography>
									</MenuItem>
								)}

								{ user?.is_superadmin &&
									<MenuItem
										onClick={() => {
											setAnchorElHome(null);
											history.push("/sysmgmt");
										}}
										sx={{
											width: "250px",
											paddingY: "15px",
											display: "flex",
											justifyContent: "space-between",
										}}
									>
										{theme.palette.mode === "dark" ? (
											<SettingsIcon />
										) : (
											<SettingsIcon />
										)}

										<Typography>
											{t("system_management")}
										</Typography>
									</MenuItem>
								}
																		
								{isSentio && (
									<MenuItem
									onClick={() => {
										setAnchorElHome(null);
										handleOpenDialog();
									}}
										sx={{
											paddingY: "15px",
											display: "flex",
											justifyContent: "space-between",
										}}
									>
										<InfoIcon />

										<Typography>
											{t("about_system")}
										</Typography>
									</MenuItem>
								)}

							</Menu>
						</Grid>

						{/* Organization name */}
						<Grid item xs={4} justifyContent="center">
							<Typography
								variant="h5"
								color={theme.palette.text.primary}
								style={{ textAlign: "center" }}
							>
								{userCtx?.user?.organization_name}
							</Typography>
						</Grid>

						{/* User Name */}
						<Grid container item xs={3} justifyContent="flex-end">
							<Typography sx={{ color: theme.palette.text.disabled,}}>
								{userCtx?.user?.first_name?.toUpperCase() + "  " + userCtx?.user?.last_name?.toLocaleUpperCase()}
							</Typography>
						</Grid>
					
						{/* Profile Menu */}
						<Grid container item xs={1} justifyContent="flex-end">
							<Tooltip
								title={
									<Typography>
										{t("open_settings")}
									</Typography>
								}
							>
								<IconButton
									onClick={handleOpenUserMenu}
									sx={{ p: 0 }}
								>
									<Avatar
										sx={{
											bgcolor: theme.palette.primary.main,
											marginRight: "5px",
										}}
									/>
								</IconButton>
							</Tooltip>
							<Menu
								sx={{ mt: "45px" }}
								id="menu-appbar"
								anchorEl={anchorElUser}
								anchorOrigin={{
									vertical: "top",
									horizontal: "right",
								}}
								keepMounted
								transformOrigin={{
									vertical: "top",
									horizontal: "right",
								}}
								open={Boolean(anchorElUser)}
								onClose={handleCloseUserMenu}
							>
								<MenuItem
									onClick={() => {
										setAnchorElUser(null);
										history.push("/profile");
									}}
								>
									<Grid
										container
										direction="row"
										width="200px"
									>
										<Grid item xs={10}>
											<Typography fontWeight="bold">
												{userCtx?.user?.first_name +
													" " +
													userCtx?.user?.last_name}
											</Typography>
											<Typography>
												{t("view_your_profile")}
											</Typography>
										</Grid>
									</Grid>
								</MenuItem>
								<MenuItem
									onClick={colorMode}
									sx={{
										paddingY: "15px",
										display: "flex",
										justifyContent: "space-between",
									}}
								>
									{theme.palette.mode === "dark" ? (
										<Brightness7Icon />
									) : (
										<Brightness4Icon />
									)}

									<Typography>
										{theme.palette.mode === "dark"
											? t("dark_mode")
											: t("light_mode")}
									</Typography>
								</MenuItem>
								<MenuItem
									sx={{
										paddingY: "15px",
										display: "flex",
										justifyContent: "space-between",
									}}
								>
									<TranslateIcon />
									<SelectLanguage />
								</MenuItem>
								<MenuItem>
									<FormGroup>
										<FormControlLabel
											control={
												<Switch
												checked={userCtx?.user?.is_auto_logout_enabled}
												onChange={() => {
													const newAutoLogout = {
														...userCtx?.user,
														is_auto_logout_enabled: !userCtx?.user?.is_auto_logout_enabled,
													} as User; // Here using type assertion to assure TypeScript that newAutoLogout conforms to User type
													userCtx?.setUser(newAutoLogout);
													localStorage.setItem("user", JSON.stringify(newAutoLogout));
												}}
											/>
																			}
											label={
												userCtx?.user
													?.is_auto_logout_enabled
													? t("auto_logout_enabled")
													: t("auto_logout_disabled")
											}
										/>
									</FormGroup>
								</MenuItem>
								<MenuItem
									sx={{
										paddingY: "15px",
										display: "flex",
										justifyContent: "space-between",
									}}
									onClick={handleLogout}
								>
									<LogoutIcon />
									{t("log_out")}
								</MenuItem>
							</Menu>

							<AboutDialog open={openDialog} onClose={handleCloseDialog} />



						</Grid>
					</Grid>
				</Toolbar>
			</Container>
		</AppBar>
	);
};
export default CMSNavbar;
