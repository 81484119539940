import React, { useEffect, useState, useRef } from 'react';
import { Card, CardContent, Typography, Grid, Button } from '@mui/material';
import { OrganizationHierarchyNode, PoolDevice } from './types'; // Ensure this type definition is appropriate for your data structure
//import EditIcon from '@material-ui/icons/Edit';
import { Table, TableBody, TableCell, TableHead, TableRow,  } from '@mui/material';
import DeviceService from "../../services/DeviceService"
import { useCookies } from 'react-cookie';
import PoolDeviceDialog  from "./PoolDeviceDialog";
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@material-ui/icons/Edit';
import AssignDeviceDialog from './AssignDeviceDialog';

interface DeviceChildrenProps {
  node: OrganizationHierarchyNode | null;
  //onSelectNode: (nodeId: string) => void;
  //setSelectedNode: React.Dispatch<React.SetStateAction<OrganizationHierarchyNode | null>>;
  onDialogClose: () => void;  // Toggling the state to trigger useEffect
  onNodeChanged: (changedNode: OrganizationHierarchyNode) => void;
  onNodeDeleted: (node_id: string, parent_id: string) => void;
  onNodeAdded: (node_id: string) => void;
  nodeParent: OrganizationHierarchyNode | null;
  //showPoolDeviceDialog: boolean;
}


const DeviceChildren: React.FC<DeviceChildrenProps> = ({ node, nodeParent  }) => {
  //const theme = useTheme();
  const [cookies, ,] = useCookies(["access_token"]);
  const [poolDevices, setPoolDevices] = useState <PoolDevice[]> ([])
  const deviceDialogMode = useRef <string> ("add")
  const [showDialog, setShowDialog] = useState (false);
  const [reloadDevices, setReloadDevices] = useState <number> (0)
  const [selectedDevice, setSelectedDevice] = useState <PoolDevice> ()

  
    useEffect (() => {
      //console.log ("Retrieve data for node:", node)
      if (!node) {
        return;
      }
      DeviceService.getPoolDevices (cookies.access_token, node.node_name).then ((response: any) => {
        //console.log ("getPoolDevices response is ", response)
        setPoolDevices (response)
        setSelectedDevice (undefined)
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [node, reloadDevices])


    // Function to find a device by device_id
    function findDeviceById(deviceId: string): PoolDevice | undefined {
      return poolDevices.find(device => device.device_id === deviceId);
    }


    const handleAddDomainDevice: () => void = () => {
      //console.log ("handleAddDomainDevice")

      deviceDialogMode.current = "add"
      setSelectedDevice (undefined)
      setShowDialog (true);
  }

  const handleSelectDevice = (device_id: string) => {
      //console.log ("Selected device:", device_id)
      deviceDialogMode.current = "edit"

      // Find the node in the list
      const device = findDeviceById (device_id);
      setSelectedDevice (device)
      setShowDialog (true);
  };

  const [availableDevices, setAvailableDevices] = useState <PoolDevice[]> ([])
  const [assignedDevices, setAssignedDevices]   = useState <PoolDevice[]> ([])
  const [openDialog, setOpenDialog] = useState(false);

/*
 * open the device assignment dialog, but first retrieve the current device assignments from the back-end
 */
const handleOpenDialog = () => {

  if (!nodeParent || !node) {
    return;
  }

  // Get the devices in the device pool for this customer
  DeviceService.getPoolDevices (cookies.access_token, nodeParent.node_name).then ((response: any) => {
    //console.log ("Assign: getPoolDevices response (nodeParent) is ", response)
    //console.log ("nodes:", nodeParent, node)
    setAvailableDevices (response)
  })

  // Get the devices currently assigned to the customer
  DeviceService.getPoolDevices (cookies.access_token, node.node_name).then ((response: any) => {
    //console.log ("Assign: getPoolDevices response (node) is ", response)
    //console.log ("nodes:", nodeParent, node)
    setAssignedDevices (response)
  })
  setOpenDialog(true);


};

const handleCloseDialog = () => {
    setOpenDialog(false);
};

const handleSaveAssignments = (newAssignedDevices: PoolDevice[]) => {
  const devicesToAdd = newAssignedDevices
      .filter(newDevice => !assignedDevices.some(existingDevice => existingDevice.device_id === newDevice.device_id))
      .map(device => device.device_id );
  
  const devicesToRemove = assignedDevices
      .filter(existingDevice => !newAssignedDevices.some(newDevice => newDevice.device_id === existingDevice.device_id))
      .map(device => device.device_id);

  // Perform actions with devicesToAdd and devicesToRemove
  //console.log('Devices to Add:', devicesToAdd);
  //console.log('Devices to Remove:', devicesToRemove);

  // Update the assignedDevices state
  setAssignedDevices(newAssignedDevices);


  // Dispatch the new assignments to the back-end or perform other necessary actions
  // Example: DeviceService.updateDeviceAssignments(devicesToAdd, devicesToRemove);
  const data = {
    parent_node : nodeParent?.node_name,
    node        : node?.node_name,
    add         : devicesToAdd,
    remove      : devicesToRemove
  }
  //console.log ("Updating assignments:", data)
  DeviceService.updateDeviceAssignments(cookies.access_token, data).then ((response: any) => {
    //console.log ("Assign: updateDeviceAssignments is ", response)
    // Close the dialog
    setOpenDialog(false);
    setReloadDevices (reloadDevices +1)
  })
};



//   

  return (
    <div>
        <PoolDeviceDialog 
            initialDevice = { selectedDevice }
            openState     = { showDialog }
            parentNode    = { node }
            setOpenState  = { setShowDialog }
            dialogMode    = { deviceDialogMode.current }
            deviceTypes   = { node?.node_type === "domain" ? (node?.devicetypes || []) : (nodeParent?.devicetypes || [])}
            onDeviceAdded = {() => { setReloadDevices (reloadDevices +1)} }
          />
          <AssignDeviceDialog
              open={openDialog}
              onClose={handleCloseDialog}
              onSave={handleSaveAssignments}
              availableDevices={availableDevices}
              assignedDevices={assignedDevices}
              />

    <Card variant="outlined">

      <CardContent >

      <Table aria-label="simple table" >
              <TableHead>
                <TableRow>

                  <TableCell align="left"> 
                    <Typography fontWeight="bold" sx={{ color: (theme) => theme.palette.text.primary}}>
                      Device ID
                    </Typography>
                  </TableCell>

                  <TableCell align="left">
                    <Typography fontWeight="bold" sx={{ color: (theme) => theme.palette.text.primary}}>
                    Device Model
                    </Typography>
                  </TableCell>

                  <TableCell align="left">
                    <Typography fontWeight="bold" sx={{ color: (theme) => theme.palette.text.primary}}>
                    Serial Number
                    </Typography>
                  </TableCell>

                </TableRow>
              </TableHead>

              <TableBody>
                {poolDevices.map((childNode: any) => (
                  <TableRow 
                  key={childNode.device_id}
                  onClick={() => handleSelectDevice (childNode.device_id)}
                  style={{ cursor: 'pointer' }}
                  >
                    <TableCell align="left">{childNode.device_id || 'No description provided'}</TableCell>
                    <TableCell align="left">{childNode.deviceType}</TableCell>
                    <TableCell align="left">{childNode.device_serialnumber}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

      </CardContent>
    </Card>

    <Grid container style={{ marginTop: 8 }} justifyContent="flex-end">
        <Button variant="outlined"
                color="primary"
                startIcon={<DownloadIcon />}
                onClick={() => {}}
            >
                Download Device Pool
          </Button>

        { node?.node_type === "domain" && (<div><Button variant="outlined"
                color="primary"
                sx={{ marginLeft: "5px"}}
                startIcon={<EditIcon />}
                onClick={handleAddDomainDevice}
            >
                Register Devices
            </Button>
          
        </div>
        )}
        { node?.node_type === "customer" && (<Button variant="outlined"
                color="primary"
                sx={{ marginLeft: "5px"}}
                startIcon={<EditIcon />}
                onClick={handleOpenDialog}
            >
                Assign Devices
            </Button>
        )}
      </Grid>

    </div>
  );
  
};


export default DeviceChildren;

/*
              availableDevices={availableDevices.filter(device => !assigned.some(d => d.device_id === device.device_id))}

*/