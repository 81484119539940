import React, { useState}  from 'react';
import { LiveDashboard } from '../../pages/Home/LiveDashboard';
import { VicuTabBar } from './CMSDashboard'; // Adjust the import path as necessary
import { Device } from "../../interfaces/Device";
import { VICU } from "../../interfaces/VICU";
import { useTranslation } from "react-i18next";
import { MapView } from "../../pages/Home/MapView/MapView";
import CustomTabs from "../tabs/CustomTabs";
import { SysPoolView } from "../../pages/Home/SysPoolView"

interface EMSDashboardTabsProps {
  visibleDevices: Device[];
  monitorGroupList: VICU[];
  setSelectedVICU: (vicu: VICU | null) => void;
  selectedVICU: VICU | null;
  updateDeviceList: () => void;
  setDevices: (devices: Device[]) => void;
  useSyspool: boolean;
}

const EMSDashboardTabs: React.FC<EMSDashboardTabsProps> = ({
  visibleDevices, monitorGroupList, setSelectedVICU, selectedVICU, updateDeviceList, setDevices, useSyspool
}) => {

    const { t } = useTranslation();
    const [currentTab, setCurrentTab] = useState<number>(0);

    // Create the base arrays
    const tabNames = [
        t("live_dashboard"),
        t("mapview")
    ];

    const tabComponents = [
        <LiveDashboard devicelist={visibleDevices}>
            <VicuTabBar
                vicuList={monitorGroupList?.filter((item: any) => item.group_name !== "syspool")}
                activateVicu={setSelectedVICU}
                activeVicu={selectedVICU}
                refreshDevices={updateDeviceList}
            />
        </LiveDashboard>,
        <MapView devices={visibleDevices} setDevices={setDevices}>
            <VicuTabBar
                vicuList={monitorGroupList?.filter((item: any) => item.group_name !== "syspool")}
                activateVicu={setSelectedVICU}
                activeVicu={selectedVICU}
                refreshDevices={updateDeviceList}
            />
        </MapView>
    ];

    if (useSyspool) {

		tabNames.push(t("manage groups"));

		tabComponents.push(
			<SysPoolView devicelist={visibleDevices} setDevices={setDevices}>
				<VicuTabBar
					vicuList={monitorGroupList}
					activateVicu={setSelectedVICU}
					activeVicu={selectedVICU}
					refreshDevices={updateDeviceList}
				/>
			</SysPoolView>
		);
	} 

    return (
		<div>
			<CustomTabs tabNames={tabNames} tabComponents={tabComponents} value={currentTab}
                onTabChange={setCurrentTab}/>
		</div>
	);

};

export default EMSDashboardTabs;