import {
    Grid,
    Typography,
    useTheme,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    FormControl,
    Box,
    Select,
    InputLabel,
    MenuItem,

} from "@mui/material";

import { useTranslation } from "react-i18next";
import { useState, useEffect, useRef } from "react";
import { useCookies } from "react-cookie";
import { OrganizationHierarchyNode  } from './types'; // Define your types in a separate file or within components
import OrgService from "../../services/OrganizationService"
import {SENTIO_TYPES, SENTIO_AI_YES_NO, SENTIO_VARIANTS} from "../../interfaces/Organization"
import IngestionServerDropdown from './IngestionServerDropdown';
import { IngestionServer } from "../../interfaces/IngestionServer";
import IngestionServerService from "../../services/IngestionServerService";
import useFetch from "../../hooks/useFetch";
import { Organization }  from "../../interfaces/Organization"

interface CustomerEditDialogProps {
    nodeData?: Organization ;
    hierarchyNode?: OrganizationHierarchyNode ;
    openState: boolean;
    setOpenState: (show:boolean) => void;
    dialogMode: string;
    parentNode?: OrganizationHierarchyNode | null;
    childNodeType? : string;
    onNodeUpdate: (node: OrganizationHierarchyNode) => void;
    onNodeDeleted: (node_id: string, parent_id: string) => void;
    onNodeAdded: (node_id: string) => void;
    triggerRefresh: () => void;
}

const MIN_HOTSTORE_DURATION   = 12;     // Hours
const MAX_HOTSTORE_DURATION   = 7 * 47; // Hours, one week
const HOTSTORE_DEFAULT_VAL    = 48;
const DEFAULT_DATA_RESOLUTION = 5 * 60; // 5 minutes
const DEFAULT_SENTIO_TYPE     = "standard";
const DEFAULT_AI_EXECUTION    = "N";

interface FetchDataResult {
    servers: IngestionServer[];
    serverLoading: boolean;
    serverError: any;
}

function useFetchIngestionSystems(refresh: number): FetchDataResult {
    const [cookies] = useCookies(['access_token']);
    const [, setTrigger] = useState (0);
    // Properly structured hook call
    const { data, loading:serverLoading, error:serverError } = useFetch({
        url: IngestionServerService.getAllIngestionServers(),
        access_token: cookies.access_token,
    });

    const [servers, setServers] = useState<IngestionServer[]>([]);

    useEffect(() => {
        if (!serverLoading && data) {
            setServers(data); // Assuming 'data' is in the correct format
        }
    }, [data, serverLoading]);

    useEffect(() => {
        // Increment trigger to refetch data when refresh is toggled
        if (refresh) {
            setTrigger(prev => prev + 1);
        }
    }, [refresh]);

    return { servers, serverLoading, serverError };
}




export const CustomerEditDialog = ({nodeData, onNodeUpdate, onNodeAdded, onNodeDeleted, triggerRefresh, hierarchyNode, parentNode, openState, setOpenState, dialogMode } : CustomerEditDialogProps) => {

    //console.log ("CustomerEditDialog:", "dialogMode:", dialogMode)
    //console.log ("nodeData:     ", nodeData)
    //console.log ("parentNode:   ", parentNode)
    //console.log ("hierarchyNode:", hierarchyNode)
    const [orgId, setOrgId] = useState <string> ("")

    const editedNode = useRef<OrganizationHierarchyNode> ();
    const theme = useTheme();
    const { t } = useTranslation();
    const [cookies, ,] = useCookies(["access_token"]);
    const [showSaveButton, setShowSaveButton] = useState (false);
    const [confirmDelete, setConfirmDelete] = useState (false);
    const [orgName, setOrgName] = useState <string> (nodeData ? nodeData?.organization_name ?? "" : "")
    const [sentioType, setSentioType]             = useState <string>(nodeData?.sentio_type || DEFAULT_SENTIO_TYPE)
    const [adminEmail, setAdminEmail]             = useState <string>(nodeData?.admin_email || "")

    const [availableLicenses, setAvailableLicenses] = useState <number> (nodeData?.num_licenses || 0)
    //const [loginDomain, setLoginDomain]           = useState <string>(nodeData?.login_domain || "")
    const [loginDomain, setLoginDomain] = useState<string>(nodeData?.login_domain ?? "");


    const [hotstoreDuration, setHotstoreDuration] = useState <number>(nodeData?.hotstore_duration || HOTSTORE_DEFAULT_VAL)
    const [dataResolution, setDataResolution]     = useState <number> (nodeData?.data_resolution || DEFAULT_DATA_RESOLUTION)
    const [dataResolutionChanged, setDataResolutionChanged] = useState (false);

    const [aiExecution, setAiExecution]               = useState <string> (nodeData?.ai_execution || DEFAULT_AI_EXECUTION)
    const [aiExecutionChanged, setAiExecutionChanged] = useState (false);

    const [sentioTypeChanged, setSentioTypeChanged]             = useState (false);
    const [orgNameChanged, setOrgNameChanged]                   = useState (false);
    const [orgIdChanged, setOrgIdChanged]                       = useState (false);
    const [hotstoreDurationChanged, setHotstoreDurationChanged] = useState (false);
    const [adminEmailChanged, setAdminEmailChanged]             = useState (false);
    const [loginDomainChanged, setLoginDomainChanged]           = useState (false);
    const [proxyServer, setProxyServer]               = useState <string> (nodeData?.proxy_device_server || "")
    const [proxyServerChanged, setProxyServerChanged] = useState (false);
    const [numLicenses, setNumLicenses] = useState <number> (nodeData?.num_licenses || 0)
    const [numLicensesChanged, setNumLicensesChanged] = useState (false)

    const [ingestionTopic, setIngestionTopic]               = useState <string> (nodeData?.ingestion_topic || "")
    const [ingestionTopicChanged, setIngestionTopicChanged] = useState (false);

    const [consumerGroup, setConsumerGroup]               = useState <string> (nodeData?.ingestion_group || "$Default")
    const [consumerGroupChanged, setConsumerGroupChanged] = useState (false);

    const [ingestionSystem, setIngestionSystem] = useState <string> (nodeData?.ingestion_system || "")
    const [ingestionSystemChanged, setIngestionSystemChanged] = useState (false);

    const [ingestionServers, setIngestionServers] = useState<IngestionServer[]>([]);
    const [refreshData, ] = useState(0);
    const { servers } = useFetchIngestionSystems(refreshData);

    const [variant, setVariant] = useState <string> (nodeData?.variant || "-")
    const [variantChanged, setVariantChanged] = useState (false)


    useEffect (() => {
        //console.log ("Ingestion servers returned:", servers)
        setIngestionServers (servers)
    }, [servers])


    useEffect(() => {
        if (!nodeData) {
            return;
        }
        //console.log ("Update with nodeData:", nodeData)
        editedNode.current = JSON.parse (JSON.stringify (nodeData));
        //console.log ("- - - - - New editedNode:", editedNode.current)
        setOrgId (dialogMode === "edit" ? nodeData.organization_id ?? "" : "");
        setOrgName (dialogMode === "edit" ? nodeData.organization_name ?? "" : "");
        setAdminEmail (dialogMode === "edit" ? nodeData.admin_email ?? "" : "");
        setLoginDomain(dialogMode === "edit" ? nodeData.login_domain ?? "" : "");

        setSentioType (dialogMode === "edit" ? nodeData.sentio_type ?? "" : "");
        setAiExecution (dialogMode === "edit" ? nodeData.ai_execution ?? DEFAULT_AI_EXECUTION : DEFAULT_AI_EXECUTION);
        setHotstoreDuration (dialogMode === "edit" ? nodeData.hotstore_duration ?? HOTSTORE_DEFAULT_VAL : HOTSTORE_DEFAULT_VAL);
        setDataResolution (dialogMode === "edit" ? nodeData.data_resolution ?? DEFAULT_DATA_RESOLUTION : DEFAULT_DATA_RESOLUTION);
        setProxyServer (dialogMode === "edit" ? nodeData.proxy_device_server ?? "" : "");
        setIngestionSystem (dialogMode === "edit" ? nodeData.ingestion_system ?? "" : "");
        setSentioType (dialogMode === "edit" ? nodeData.sentio_type ?? "" : "");
        setIngestionTopic (dialogMode === "edit" ? nodeData.ingestion_topic ?? "" : "");
        setConsumerGroup (dialogMode === "edit" ? nodeData.ingestion_group ?? "$Default" : "$Default");
        setSentioType (dialogMode === "edit" ? nodeData.sentio_type ?? "" : "");
        setSentioType (dialogMode === "edit" ? nodeData.sentio_type ?? "" : "");
        setNumLicenses (dialogMode === "edit" ? nodeData.num_licenses ?? 0 : 0)
        setVariant (dialogMode === "edit" ? nodeData.variant ?? "" : "");
        setOrgIdChanged (false)
        setOrgNameChanged (false)
        setAdminEmailChanged (false)
        setLoginDomainChanged (false)
        setSentioTypeChanged (false)
        setHotstoreDurationChanged (false)
        setDataResolutionChanged (false)
        setAiExecutionChanged (false)
        setProxyServerChanged (false)
        setIngestionTopicChanged (false)
        setConsumerGroupChanged (false)
        setIngestionSystemChanged (false)
        setNumLicensesChanged (false)
        setVariantChanged (false);

    }, [nodeData, dialogMode]);  // Depend on nodeData and dialogMode to update orgId
    
    // Calculate how many licenses are available for this child node.
    useEffect (() => {
        if (!parentNode)  {
            return;
        }
        const parent_pool     = parentNode.licensePool;
        const parent_assigned = parentNode.numAssignedLicenses;
        const nodePool        = nodeData?.num_licenses || 0;//?.licensePool || 0   ;//editedNode.current?.licensePool || 0; //nodeData?.numLicenses || 0
        const available       = parent_pool - parent_assigned + nodePool;
        setAvailableLicenses (available)
        
        //console.log ("---------- Computing Available Licenses --------------")
        //console.log ("Parent node:", parentNode)
        //console.log ("item node:",nodeData)
        //console.log ("parent pool:", parent_pool, "parent assigned:", parent_assigned, "nodePool:", nodePool)
        //console.log ("Available licenses are ", available)
        
    }, [parentNode, nodeData])


    const handleClose = () => {
        setOpenState (false);
    }

    
    const handleSaveChanges = () => {
        console.log ("Handle save changes:", nodeData, dialogMode, parentNode)


        if (dialogMode === "add") {
            const data = {
                "organization_id"     : orgId,
                "organization_name"   : orgName,
                "admin_email"         : adminEmail,
                "data_resolution"     : dataResolution,
                "hotstore_duration"   : hotstoreDuration,
                "ingestion_topic"     : ingestionTopic,
                "ingestion_group"     : consumerGroup,
                "ai_execution"        : aiExecution,
                "sentio_type"         : sentioType,
                "proxy_device_server" : proxyServer,
                "login_domain"        : loginDomain,
                "ingestion_system"    : ingestionSystem,
                "parent_node"         : parentNode?.node_name,
                "num_licenses"        : numLicenses,
                "variant"             : variant
            }
            //console.log ("edit parameters:", data)
            OrgService.addOrganization (data, cookies.access_token)
                .then((res: any) => {
                    console.log("result:", res);
                    setShowSaveButton (false);
                })
                .catch((err: any) => {
                    console.log(err);
                }).finally (() => {
                    onNodeAdded (orgId)
                    triggerRefresh ();
                    handleClose();
                });

            handleClose();
            return;
        } // if "add"


        // We have an EDIT
        if (!nodeData) {
            handleClose();
            return;
        }
        const data = {
            "organization_id"     : orgId,
            "organization_name"   : orgName,
            "admin_email"         : adminEmail,
            "data_resolution"     : dataResolution,
            "hotstore_duration"   : hotstoreDuration,
            "ingestion_topic"     : ingestionTopic,
            "ingestion_group"     : consumerGroup,
            "ai_execution"        : aiExecution,
            "sentio_type"         : sentioType,
            "proxy_device_server" : proxyServer,
            "login_domain"        : loginDomain,
            "ingestion_system"    : ingestionSystem,
            "num_licenses"        : numLicenses,
            "variant"             : variant
        }
        console.log ("Save Data:", data)
        OrgService.editOrganization (data, cookies.access_token)
        .then((res: any) => {

            if (hierarchyNode && (orgNameChanged || numLicensesChanged)) {
                const data = {
                    "node_name"    : hierarchyNode.node_name,
                    "description"  : orgName,
                    "num_licenses" : numLicenses,
                }
                console.log ("EDIT hierarhcyNode:", data)
        
                OrgService.editHierarchy (data, cookies.access_token)
                .then((res: any) => {
                    console.log("result:", res);
                    //targetOrg.organization_name = orgName;
                    //            window.location.href = "/sysmgmt";
                })
                .catch((err: any) => {
                    console.log(err);
                }).finally (() => {
                    triggerRefresh ();
                    handleClose();
                });

            }

            //console.log("result:", res);
            //nodeData.organization_name = orgName;
            setShowSaveButton (false);
            setOrgIdChanged (false)
            setOrgNameChanged (false)
            setAdminEmailChanged (false)
            setLoginDomainChanged (false)
            setSentioTypeChanged (false)
            setHotstoreDurationChanged (false)
            setDataResolutionChanged (false)
            setAiExecutionChanged (false)
            setProxyServerChanged (false)
            setIngestionTopicChanged (false)
            setConsumerGroupChanged (false)
            setIngestionSystemChanged (false)
            setNumLicensesChanged (false)
            setVariantChanged (false)
            //setNodeData (nodeData)
        })
        .catch((err: any) => {
            console.log(err);
        }).finally (() => {
            if (hierarchyNode) {
                onNodeUpdate (hierarchyNode)
            }

            if (editedNode.current) {
                onNodeUpdate(editedNode.current); // This now triggers the update in the parent component
            }

            triggerRefresh ();
            handleClose();
        });
    } // handleSaveChanges

    useEffect (() => {
        var showSave = false;
        switch (dialogMode) {
            case "edit" : {
                showSave = sentioTypeChanged || adminEmailChanged || orgNameChanged || loginDomainChanged || 
                hotstoreDurationChanged || dataResolutionChanged ||
                 proxyServerChanged || 
                ingestionTopicChanged || consumerGroupChanged  || orgIdChanged ||
                ingestionSystemChanged || numLicensesChanged || variantChanged
                break;
            }

            case "add" : {
                showSave = orgName !== '' && orgId !== '' &&  ingestionTopic !== '' && consumerGroup !== '' && ingestionSystem !== '';
                                
                break;
            }
        }
        setShowSaveButton (showSave)

    }, [dialogMode, sentioTypeChanged, adminEmailChanged, orgNameChanged, loginDomainChanged, 
        hotstoreDurationChanged,  dataResolutionChanged, variantChanged,
         proxyServerChanged,  ingestionSystemChanged, numLicensesChanged,
        ingestionTopicChanged, consumerGroupChanged, orgIdChanged,  ingestionSystem,
        adminEmail, orgName, orgId, ingestionTopic,  consumerGroup])


    const deleteVICU = () => {
        setConfirmDelete (true)
    };

    const cancelDelete = () => {
        setConfirmDelete (false)
    };

    const yesDelete = () => {
        console.log ("yesDelete: nodeData=", nodeData, "parentNode=",parentNode)
        if (!parentNode || !hierarchyNode) {
            return;
        }

        const organization_id = hierarchyNode.id;
        const parent_id       = parentNode.node_name;
        //OrgService.deleteCustomer ( cookies.access_token, organization_id, parent_id)
        OrgService.deleteHierarchyNode ( cookies.access_token, organization_id, parent_id)
        .then((res: any) => {
            setConfirmDelete (false)
            onNodeDeleted (organization_id, parentNode.id)
            triggerRefresh ();
            handleClose();
        })
        .catch((err: any) => {
            console.log(err);
        });

    };

    
    const handleItemChange = (id: string, data: any) => {
        // eslint-disable-next-line
        const urlPattern = /^((https?:)?(\/\/)?([\da-z.-]+)?(\.[a-z.]{2,6})?([\/\w.-]*)*\/?)?$/;
        // eslint-disable-next-line
        const addressPattern = /^(https?:)?(\/\/)?([\da-z.-]+)?(\.[a-z.]{2,6})?(:\d{0,5})?([\/\w .-]*)*\/?$/;

        const stringPattern = /^[a-zA-Z0-9_-]*$/;
        switch (id) {

            case "orgId" : {
                const value: string = data;
                if (stringPattern.test(value) || value === '') {
                    if (editedNode.current) editedNode.current.organization_id = value;
                    setOrgId(value);
                }
            
                setOrgIdChanged (value !== nodeData?.organization_id)
                break;
            }

            case "orgName" : {
                const val: string = data;
                if (editedNode.current) { 
                    editedNode.current.organization_name = val;
                    editedNode.current.description = val;
                }
                setOrgName (val);
                setOrgNameChanged (val.trim() !== nodeData?.organization_name)
                break;
            }

            case "adminEmail" : {
                const val: string = data;
                if (editedNode.current) editedNode.current.admin_email = val;
                setAdminEmail (val);
                setAdminEmailChanged (val.trim() !== nodeData?.admin_email)
                break;
            }

            case "loginDomain" : {
                const value: string = data;
                if (urlPattern.test(value) || value === '') {
                    if (editedNode.current) editedNode.current.login_domain = value;
                    setLoginDomain (value);
                }
                setLoginDomainChanged (value !== nodeData?.login_domain)
                break;
            }

            case "sentioType" : {
                const val: string = data;
                if (editedNode.current) editedNode.current.sentio_type = val;
                setSentioType(val);
                setSentioTypeChanged (val !== nodeData?.sentio_type)
                console.log (val !== nodeData?.sentio_type)
                break;
            }

            case "variant" : {
                const val: string = data;
                if (editedNode.current) editedNode.current.variant = val;
                setVariant(val);
                setVariantChanged (val !== nodeData?.variant)
                break;
            }

            case "hotStoreDuration" : {
                const value: number = Number (data as number);
                if (editedNode.current) editedNode.current.hotstore_duration = value;
                setHotstoreDuration(value);
                setHotstoreDurationChanged (value !== nodeData?.hotstore_duration)
                break;
            }

            case "dataResolution" : {
                const value: number = Number (data as number);
                if (editedNode.current) editedNode.current.data_resolution = value;
                setDataResolution(value);
                setDataResolutionChanged (value !== nodeData?.data_resolution)
                break;
            }

            case "aiExecution" : {
                const val: string = data;
                if (editedNode.current) editedNode.current.ai_execution = val;
                setAiExecution(val);
                setAiExecutionChanged (val !== nodeData?.ai_execution)
                break;
            }

            case "proxyServer" : {
                const value: string = data;
                if (addressPattern.test(value) || value === '') {
                    if (editedNode.current) editedNode.current.proxy_device_server = value;
                    setProxyServer (value);
                }
                setProxyServerChanged (value !== nodeData?.proxy_device_server)
                break;
            }


            case "ingestionTopic" : {
                const value: string = data;
                if (stringPattern.test(value) || value === '') {
                    if (editedNode.current) editedNode.current.ingestion_topic = value;
                    setIngestionTopic(value);
                  }
                setIngestionTopicChanged (value !== nodeData?.ingestion_topic)
                break;
            }

            case "consumerGroup" : {
                const val: string = data;
                if (editedNode.current) editedNode.current.ingestion_group = val;
                setConsumerGroup(val);
                setConsumerGroupChanged (val !== nodeData?.ingestion_group)
                break;
            }


            case "IngestionSystem" : {
                const old_system = nodeData?.ingestion_system ? nodeData.ingestion_system : ""
                const val: string = data;

                console.log ("New Ingestion System:", val)
                if (editedNode.current) editedNode.current.ingestion_system = val;
                setIngestionSystem(val);
                setIngestionSystemChanged (val !== old_system)
                //setIngestionKeyChanged (val !== nodeData?.ingestion_key)
                break;
            }
            case "numLicenses" : {
                var value: number = Number (data as number);

                if (value > availableLicenses) {
                    value = availableLicenses;
                }
                if (editedNode.current) { editedNode.current.licensePool = value; }

                setNumLicenses (value)
                setNumLicensesChanged (value !== nodeData?.num_licenses)
                //setNumLicenses(value);
                break;
            }

        } // switch


    } // handleItemChange
   
    //console.log ("Org info:", orgInfo)
    //console.log ("nodeData before rendering:", orgId)
    return (
    <>
        <Dialog open={confirmDelete} >
            <DialogTitle> {t ("confirm_organization_delete") } </DialogTitle>
            <DialogContent>
                <Typography>{t("delete_organization_notification")}</Typography>
            </DialogContent>
            <DialogActions>
                <Grid container direction="row" justifyContent="space-between"  alignItems="center" >
                    <Button variant="outlined" onClick={cancelDelete}> { t("cancel") }</Button>
                    <Button variant="contained" onClick={yesDelete} color="warning">  { t("yes_delete") }</Button>
                </Grid>
            </DialogActions>
        </Dialog>


        <Dialog  open = {openState} onClose={handleClose}  maxWidth="lg" >
            <DialogTitle>
                <Grid container alignItems="center">
                    <Grid item xs>
                        <Box>{t("customer_administration")}</Box>
                    </Grid>

                    <Grid item>
                        <TextField
                            label={t("organization_id")}
                            id="org_id"
                            type=""
                            value={orgId}
                            onChange={(e) => handleItemChange("orgId", e.target.value)}
                            disabled = {dialogMode === "edit"}
                            InputLabelProps={{ shrink: true }}   
                            placeholder = "Enter unique ID"                 
                            sx={{
                                input: { color: theme.palette.text.primary, 
                                        background: !orgIdChanged || !nodeData ? theme.palette.background.default : theme.palette.warning.light},                                    
                                label: { color: theme.palette.text.primary },
                            }}
                        />

                    </Grid>

                </Grid>
            </DialogTitle>


            <DialogContent>

            <Grid container item pt={3} mb={1}  spacing={2}>

                {/* First Row */}
                <Grid item xs={12}>
                    <Grid container direction="row" columnSpacing={1}>
                        <Grid item xs={4}>
                            <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            label={t("organization_name")}
                            id="org_name"
                            value={orgName}
                            placeholder = "Descriptive name to be shown in the GUI"
                            onChange={(e) => handleItemChange("orgName", e.target.value)}
                            sx={{
                                input: { color: theme.palette.text.primary, 
                                         background: !orgNameChanged || !nodeData ? theme.palette.background.default : theme.palette.warning.light},                                    
                                label: { color: theme.palette.text.primary },
                            }}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            type="email"
                            label={t("admin_email")}
                            id="admin_email"
                            placeholder = "Email address of administrator"
                            value={adminEmail}
                            onChange={(e) => handleItemChange("adminEmail", e.target.value)}
                            sx={{
                                input: { color: theme.palette.text.primary, 
                                         background: !adminEmailChanged || !nodeData ? theme.palette.background.default : theme.palette.warning.light},
                                label: { color: theme.palette.text.primary },
                            }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            label={t("login_domain")}
                            id="login_domain"
                            placeholder = "Web address for user login"                 
                            value={loginDomain}
                            onChange={(e) => handleItemChange("loginDomain", e.target.value)}
                            sx={{
                                input: { color: theme.palette.text.primary, 
                                         background: !loginDomainChanged || !nodeData ? theme.palette.background.default : theme.palette.warning.light},
                                label: { color: theme.palette.text.primary },
                            }}
                            />
                        </Grid>
                    </Grid>

                </Grid> {/* Row Container */}


                {/* Second Row */}
                <Grid item xs={12}>

                    <Grid container direction="row" columnSpacing={1}>

                        <Grid item >
                            <FormControl fullWidth>
                                <InputLabel
                                    sx={{ color: theme.palette.text.primary }}
                                    id="sentioInputLabel"
                                    shrink={sentioType !== ""}
                                >
                                    {t("sentioweb_variant")}
                                </InputLabel>
                                
                                <Box sx={{ backgroundColor: !sentioTypeChanged || !nodeData ? theme.palette.background.default : theme.palette.warning.light, width: "200px" }}>
                                    <Select
                                    labelId="sentioInputLabel"
                                    value={sentioType}
                                    label={t("sentio_type")}
                                    disabled={false}
                                    onChange={(e) => handleItemChange("sentioType", e.target.value)}
                                    displayEmpty
                                    sx={{ width: "100%" }} 
                                    >
                                    {Object.entries(SENTIO_TYPES).map(([key, value]) => (
                                        <MenuItem key={key} value={key}>
                                        {value}
                                        </MenuItem>
                                    ))}
                                    </Select>
                                </Box>
                            </FormControl>
                        </Grid>


                        <Grid item >
                            <FormControl fullWidth>
                                <InputLabel
                                    sx={{ color: theme.palette.text.primary }}
                                    id="sentioVariantLabel"
                                    shrink={variant !== ""}
                                >
                                    {t("sentioweb_variant")}
                                </InputLabel>
                                
                                <Box sx={{ backgroundColor: !sentioTypeChanged || !nodeData ? theme.palette.background.default : theme.palette.warning.light, width: "200px" }}>
                                    <Select
                                    labelId="variantInputLabel"
                                    value={variant}
                                    label={t("sentio_variant")}
                                    disabled={false}
                                    onChange={(e) => handleItemChange("variant", e.target.value)}
                                    displayEmpty
                                    sx={{ width: "100%" }} 
                                    >
                                    {Object.entries(SENTIO_VARIANTS).map(([key, value]) => (
                                        <MenuItem key={key} value={key}>
                                        {value}
                                        </MenuItem>
                                    ))}
                                    </Select>
                                </Box>
                            </FormControl>


                        </Grid>






                        <Grid item >
                            <FormControl fullWidth>

                                <InputLabel
                                    sx={{ color: theme.palette.text.primary }}
                                    id="aiInputLabel"
                                    shrink={aiExecution !== ''}
                                    >
                                    {t("ai_execution")}
                                </InputLabel>
                                <Box sx={{ backgroundColor: !aiExecutionChanged || !nodeData ? theme.palette.background.default : theme.palette.warning.light }}>

                                    <Select
                                        labelId="aiInputLabel"
                                        value={  aiExecution   }
                                        label={t("ai_execution")}
                                        disabled={false}
                                        onChange={(e) => handleItemChange ("aiExecution", e.target.value)}
                                        displayEmpty
                                        sx={{
                                            input: { color: theme.palette.text.primary, 
                                                    background: !adminEmailChanged || !nodeData ? theme.palette.background.default : theme.palette.warning.light},
                                            label: { color: theme.palette.text.primary },
                                            width: {md:100}
                                        }}
                                        >
                                        {Object.entries(SENTIO_AI_YES_NO).map(([key, value]) => (
                                            <MenuItem key={key} value={key}>
                                                {t(value)}
                                            </MenuItem>
                                            ))}

                                    </Select>
                                    </Box>
                            </FormControl>
                        </Grid>



                        <Grid item >
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                type="number"
                                label={t("hotstore_duration")}
                                id="hotstore_duration"
                                value={hotstoreDuration}
                                onChange={(e) => handleItemChange ("hotStoreDuration", e.target.value)}
                                sx = {{ 
                                    input: { color: theme.palette.text.primary, 
                                             background: !hotstoreDurationChanged || !nodeData ? theme.palette.background.default : theme.palette.warning.light},
                                    label: { color: theme.palette.text.primary },
                                    width: { md:150 }
                                }}
                                inputProps={{
                                    min: MIN_HOTSTORE_DURATION,
                                    max: MAX_HOTSTORE_DURATION,
                                  }}
                            />
                        </Grid>

                        <Grid item >
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                label={t("data_resolution")}
                                id="data_resolution"
                                type="number"
                                onChange={(e) => handleItemChange ("dataResolution", e.target.value)}
                                value={dataResolution}
                                sx = {{ 
                                    input: { color: theme.palette.text.primary, 
                                             background: !dataResolutionChanged || !nodeData ? theme.palette.background.default : theme.palette.warning.light},
                               label: { color: theme.palette.text.primary },
                                    width: { md:120 }
                                }}
                            />
                        </Grid>

                        <Grid item >
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                label={t("rest_proxy_Server")}
                                id="rest_proxy_Server"
                                value={proxyServer}
                                placeholder = "REST Proxy Server Address"
                                onChange={(e) => handleItemChange ("proxyServer", e.target.value)}
                                sx = {{ 
                                    input: { color: theme.palette.text.primary, 
                                             background: !proxyServerChanged || !nodeData ? theme.palette.background.default : theme.palette.warning.light},
                                    label: { color: theme.palette.text.primary },
                                    width: {md:300}
                                }}
                            />
                        </Grid>

                        <Grid item >
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>

                            <TextField
                                InputLabelProps={{ shrink: true }}
                                type="number"
                                label={t("license_pool")}
                                id="license_pool"
                                value={numLicenses}
                                onChange={(e) => handleItemChange ("numLicenses", e.target.value)}
                                sx = {{ 
                                    input: { color: theme.palette.text.primary, 
                                             background: !numLicensesChanged || !nodeData ? theme.palette.background.default : theme.palette.warning.light},
                                    label: { color: theme.palette.text.primary },
                                    width: { md:120 }
                                }}
                                inputProps={{
                                    min: 0,
                                    max: availableLicenses
                                  }}
                            /> <Typography 
                                    onClick={ () => { handleItemChange ("numLicenses", availableLicenses)}}
                                    sx={{
                                    cursor: 'pointer',
                                    color: theme.palette.primary.main, // Customize color if needed
                                    '&:hover': {
                                        textDecoration: 'underline',
                                    },
                                    }}>
                                Max: { availableLicenses}
                            </Typography>
                            </Box>
                        </Grid>

                    </Grid> 
                </Grid> {/* Row Container */}

                {/* Third Row */}                
                <Grid item xs={12}>
                    <Grid container direction="row" columnSpacing={1}>
                        
                        <Grid item>
                            <IngestionServerDropdown 
                                ingestionServers={ingestionServers} 
                                defaultSelectedServer={ingestionSystem}
                                //onSelect={handleSelection} 
                                onSelect = {(e) => handleItemChange ("IngestionSystem", e)}
                                serverChanged = { dialogMode === "edit" ? ingestionSystemChanged : false }
                           />
                        </Grid>

                        <Grid item >
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                label={t("ingestion_topic")}
                                id="ingestion_topic"
                                placeholder = "Kafka topic ID"
                                value={ingestionTopic}
                                onChange={(e) => handleItemChange ("ingestionTopic", e.target.value)}
                                sx = {{ 
                                    input: { color: theme.palette.text.primary, 
                                             background: !ingestionTopicChanged || !nodeData ? theme.palette.background.default : theme.palette.warning.light},
                                    label: { color: theme.palette.text.primary },
                                    width: {md:300}
                                }}
                            />
                        </Grid>

                        <Grid item >
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                label={t("consumer_group")}
                                id="consumer_group"
                                value={consumerGroup}
                                onChange={(e) => handleItemChange ("consumerGroup", e.target.value)}
                                sx = {{ 
                                    input: { color: theme.palette.text.primary, 
                                             background: !consumerGroupChanged || !nodeData ? theme.palette.background.default : theme.palette.warning.light},
                                    label: { color: theme.palette.text.primary },
                                    width: {md:200}

                                }}
                            />
                        </Grid>
                    </Grid> 
                    </Grid> {/* Row Container */}
           
            
            </Grid> {/* Outer container */}

                
            


            </DialogContent>

            <DialogActions>
                <Grid container direction="row" justifyContent="space-between"  alignItems="center" >
                    <Button variant="outlined" onClick={handleClose}> { t("close") }</Button>
                    {showSaveButton && (
								<Button onClick={handleSaveChanges} variant="outlined"
                                        sx={{background: theme.palette.warning.light}}
                                >
									{t("save_changes")}
								</Button>
							)}

                    { dialogMode === "edit" && 
                        <Button variant="outlined" onClick={deleteVICU} >  { t("delete_organization") }</Button>
                    }
                </Grid>
            </DialogActions>

        </Dialog>

    </>
    )
}
