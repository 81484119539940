import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, List, ListItem, ListItemText, IconButton, Typography } from '@mui/material';
import { PoolDevice } from './types';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';

interface AssignDeviceDialogProps {
    open: boolean;
    onClose: () => void;
    onSave: (assignedDevices: PoolDevice[]) => void;
    availableDevices: PoolDevice[];
    assignedDevices: PoolDevice[];
}

const AssignDeviceDialog: React.FC<AssignDeviceDialogProps> = ({ open, onClose, onSave, availableDevices, assignedDevices }) => {
    const [assigned, setAssigned] = useState<PoolDevice[]>(assignedDevices);
    const [available, setAvailable] = useState<PoolDevice[]>(availableDevices);
    const [searchTerm, setSearchTerm] = useState<string>('');

    useEffect(() => {
        setAvailable(availableDevices);
    }, [availableDevices]);

    useEffect(() => {
        setAssigned(assignedDevices);
    }, [assignedDevices]);

    const handleAssign = (device: PoolDevice) => {
        setAssigned(prev => [...prev, device]);
        setAvailable(prev => prev.filter(d => d.device_id !== device.device_id));
    };

    const handleRemove = (device: PoolDevice) => {
        setAssigned(prev => prev.filter(d => d.device_id !== device.device_id));
        setAvailable(prev => [...prev, device]);
    };

    const handleSelectAll = (isAssign: boolean) => {
        if (isAssign) {
            setAssigned(prev => [...prev, ...available]);
            setAvailable([]);
        } else {
            setAvailable(prev => [...prev, ...assigned]);
            setAssigned([]);
        }
    };

    const filterDevices = (devices: PoolDevice[], searchTerm: string) => {
        const lowercasedTerm = searchTerm.toLowerCase();
        return devices.filter(device =>
            device.device_serialnumber.toLowerCase().includes(lowercasedTerm) ||
            device.deviceType.toLowerCase().includes(lowercasedTerm) ||
            device.hierarchyNode.toLowerCase().includes(lowercasedTerm)
        );
    };

    const filteredAvailable = filterDevices(available, searchTerm);
    const filteredAssigned = filterDevices(assigned, searchTerm);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>
                Assign Devices
                <IconButton onClick={onClose} style={{ position: 'absolute', right: 8, top: 8 }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <TextField
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    placeholder="Search devices..."
                    InputProps={{
                        startAdornment: <SearchIcon />
                    }}
                    onChange={e => setSearchTerm(e.target.value)}
                />

            <div style={{ display: 'flex', height: 400 }}>
                <div style={{ flex: 1, marginRight: 10 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h6">Available Devices</Typography>
                        <Button onClick={() => handleSelectAll(true)}>Select All</Button>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
                        <Typography variant="subtitle1">Serial Number</Typography>
                        <Typography variant="subtitle1">Device Type</Typography>
                    </div>
                    <List style={{ maxHeight: 300, overflow: 'auto' }}>
                        {filteredAvailable.map((device, index) => (
                            <ListItem
                                key={device.device_id}
                                button
                                onClick={() => handleAssign(device)}
                                style={{
                                    padding: '4px 16px',
                                    backgroundColor: index % 2 === 0 ? '#f0f0f0' : '#ffffff'
                                }}
                            >
                                <ListItemText
                                    primary={
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                            <span>{device.device_serialnumber}</span>
                                            <span>{device.deviceType}</span>
                                        </div>
                                    }
                                />
                            </ListItem>
                        ))}
                    </List>
                </div>
                <div style={{ width: '2px', backgroundColor: 'grey', margin: '0 10px' }}></div>
                <div style={{ flex: 1, marginLeft: 10 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h6">Assigned Devices</Typography>
                        <Button onClick={() => handleSelectAll(false)}>Deselect All</Button>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
                        <Typography variant="subtitle1">Serial Number</Typography>
                        <Typography variant="subtitle1">Device Type</Typography>
                    </div>
                    <List style={{ maxHeight: 300, overflow: 'auto' }}>
                        {filteredAssigned.map((device, index) => (
                            <ListItem
                                key={device.device_id}
                                button
                                onClick={() => handleRemove(device)}
                                style={{
                                    padding: '4px 16px',
                                    backgroundColor: index % 2 === 0 ? '#f0f0f0' : '#ffffff'
                                }}
                            >
                                <ListItemText
                                    primary={
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                            <span>{device.device_serialnumber}</span>
                                            <span>{device.deviceType}</span>
                                        </div>
                                    }
                                />
                            </ListItem>
                        ))}
                    </List>
                </div>
            </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
                <Button onClick={() => onSave(assigned)} variant="contained" color="primary">Save</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AssignDeviceDialog;
