import { User } from "../interfaces/user/User";
import * as CONSTANTS from "../utils/constants/constants";

const axios = require("axios").default;
class UserService {

    static getMyProfile (access_token?: string) {
        return axios
            .get(CONSTANTS.HOST + "api/v1/sysadmin/users/?myinfo=true", {
                headers: { authorization: "Bearer " + access_token },
            })
            .then((response: any) => response.data)
            .catch((err: any) => console.log(err));

    } // getMyProfile

    static getAllHierarchyUsers (access_token?: string) {
        return axios
            .get(CONSTANTS.HOST + "api/v1/sysadmin/users/", {
                headers: { authorization: "Bearer " + access_token },
            })
            .then((response: any) => response.data)
            .catch((err: any) => console.log(err));

    } // getAllHierarchyUsers


    static getMe(access_token?: string) {
        return axios
            .get(CONSTANTS.HOST + "api/utils/me/profile/", {
                headers: { authorization: "Bearer " + access_token },
            })
            .then((response: any) => response.data)
            .catch((err: any) => console.log(err));
    }

    static getAllUsers (access_token?: string) {
        return CONSTANTS.HOST + "api/users/all/"
    }

    
    // Retrieve possible admin users for a particular node
    static getNodeAdmins (access_token: string, node_name: string) {
        return axios
            .get (CONSTANTS.HOST + "api/v1/sysadmin/adminusers/?node_name=" + node_name, {
                headers: { authorization: "Bearer " + access_token },
            })
            .then((response: any) => response.data)
            .catch((err: any) => console.log(err));
    } // getAdminUsers

    static removeNodeAdmin(access_token: string, node_name: string, username: string) {
        return axios
            .delete(CONSTANTS.HOST + "api/v1/sysadmin/adminusers/?node_name=" + node_name + "&username=" + username, {
                headers: { authorization: "Bearer " + access_token },
            })
            .then((response: any) => response)
            .catch((err: any) => {
                console.log(err);
                return err.data;
            });
    }


    static editProfile(access_token?: string, image?: string) {
        const dataString = JSON.stringify(image);
        return axios
            .patch(
                CONSTANTS.HOST + "api/utils/me/edit/",
                { image: dataString },
                { headers: { authorization: "Bearer " + access_token } }
            )
            .then((response: any) => response)
            .catch((err: any) => {
                console.log(err);
                return err.data;
            });
    }
    static enableMFA(access_token?: string) {
        return axios.post(
            CONSTANTS.HOST + "auth/email/activate/",
            {},
            {
                headers: { authorization: "Bearer " + access_token },
            }
        );
    }
    static confirmMFA(code: string, access_token?: string) {
        return axios
            .post(
                CONSTANTS.HOST + "auth/email/activate/confirm/",
                { code: code },
                {
                    headers: { authorization: "Bearer " + access_token },
                }
            )
            .then((response: any) => response.data)
            .catch((err: any) => {
                console.log(err);
                return err.data;
            });
    }
    static  disableMFA(code: string, access_token?: string) {
        return axios.post(
            CONSTANTS.HOST + "auth/email/deactivate/",
            { code: code },
            {
                headers: { authorization: "Bearer " + access_token },
            }
        );
    }
    static getCode(access_token?: string) {
        return axios
            .post(
                CONSTANTS.HOST + "auth/code/request/",
                {
                    method: "email",
                },
                { headers: { authorization: "Bearer " + access_token } }
            )
            .then((response: any) => response.data)
            .catch((err: any) => {
                console.log(err);
                return err.data;
            });
    }
    static regenerateBackupCodes(code: string, access_token?: string) {
        return axios
            .post(
                CONSTANTS.HOST + "auth/email/codes/regenerate/",
                {
                    code: code,
                },
                {
                    headers: { authorization: "Bearer " + access_token },
                }
            )
            .then((response: any) => response.data)
            .catch((err: any) => {
                console.log(err);
                return err.data;
            });
    }
    static blacklistToken(refresh_token?: string) {
        return axios.post(CONSTANTS.HOST + "api/o/token/blacklist/", {
            refresh: refresh_token,
        });
    }

    static setPassword (access_token: string, old_pass: string, new_pass: string) {
        console.log ("Setpassword:", old_pass, new_pass, access_token)
        const headers = { authorization: "Bearer " + access_token };
        const data = {
            old_password: old_pass,
            new_password: new_pass
        }
        return axios
            .put(CONSTANTS.HOST + "api/setpass/", data, { headers }
            )
            .then((response: any) => response.data)
            .catch((err: any) => console.log(err));
    }

    static forgotPassword (email: string) {
        const data = {
            email: email
        }
        return axios
            .post(CONSTANTS.HOST + "api/password_reset/", data) 
            .then((response: any) => response.data)
            .catch((err: any) => console.log(err));
    }

    static confirmResetPassword (password: string, token: string) {
        const data = {
            password: password,
            token: token
        }
        return axios
            .post(CONSTANTS.HOST + "api/password_reset/confirm/", data) 
            .then((response: any) => response)
            .catch((err: any) => {
                console.log ("Userservice:", err)
                return err;
            });
    }

    static createUser (userData: User, token: string) {
        const data = userData;

        return axios
            .post(CONSTANTS.HOST + "api/v1/sysadmin/users/", data, {
                headers: { authorization: "Bearer " + token }

            }) 
            .then((response: any) => response)
            .catch((err: any) => {
                console.log ("Userservice:", err)
                return err;
            });
    } // createUser

    static deleteUser (username:string, token: string) {

        return axios
            .delete(CONSTANTS.HOST + "api/v1/sysadmin/users/?username=" + username, {
                headers: { authorization: "Bearer " + token }
            }) 
            .then((response: any) => response)
            .catch((err: any) => {
                console.log ("Userservice:", err)
                return err;
            });
    } // createUser


    static modifyUser (userData: User, token: string) {
        const data = userData;

        return axios
            .patch(CONSTANTS.HOST + "api/v1/sysadmin/users/", data, {
                headers: { authorization: "Bearer " + token }

            }) 
            .then((response: any) => response)
            .catch((err: any) => {
                console.log ("Userservice:", err)
                return err;
            });
    } // createUser

}

export default UserService;
