import * as CONSTANTS from "../utils/constants/constants";
const axios = require("axios").default;

class DeviceService {
    static getDevice() {
        return CONSTANTS.HOST + "api/devices/REPLACE/";
    }
    static getDevices() {
        return CONSTANTS.HOST + "api/devices/";
    }
    static getDeviceStatus() {
        return CONSTANTS.HOST + "api/devices/REPLACE/status/";
    }
    static getTypes() {
        return CONSTANTS.HOST + "api/devices/types/alarm-profiles/";
    }
    static assignDevice(device_id: string, patient_id: string, access_token: string) {
        return axios
            .patch(
                CONSTANTS.HOST +
                    "api/devices/" +
                    device_id +
                    "/patients/" +
                    patient_id +
                    "/assign/",
                { device_id, patient_id },
                {
                    headers: { authorization: "Bearer " + access_token },
                }
            )
            .then((response: any) => response)
            .catch((err: any) => {
                console.log(err);
                return err.data;
            });
    }
    static unAssignDevice(
        device_id: string,
        patient_id: string,
        access_token: string
    ) {
        return axios
            .patch(
                CONSTANTS.HOST +
                    "api/devices/" +
                    device_id +
                    "/patients/" +
                    patient_id +
                    "/unassign/",
                { device_id, patient_id },
                {
                    headers: { authorization: "Bearer " + access_token },
                }
            )
            .then((response: any) => response)
            .catch((err: any) => {
                console.log(err);
                return err.data;
            });
    }
    static addDevice(data: any, access_token: string) {
        return axios
            .post(CONSTANTS.HOST + "api/devices/", data, {
                headers: { authorization: "Bearer " + access_token },
            })
            .then((response: any) => response)
            .catch((err: any) => {
                console.log(err);
                return err.response;
            });
    }
    static editDevice(data: any, access_token: string, device_id: string) {
        return axios
            .patch(CONSTANTS.HOST + "api/devices/" + device_id + "/", data, {
                headers: { authorization: "Bearer " + access_token },
            })
            .then((response: any) => response)
            .catch((err: any) => {
                console.log(err);
                return err.data;
            });
    }
    static deleteDevice(device_id: string, access_token: string) {
        return axios
            .delete(CONSTANTS.HOST + "api/devices/" + device_id + "/", {
                headers: { authorization: "Bearer " + access_token },
            })
            .then((response: any) => response)
            .catch((err: any) => {
                console.log(err);
                return err.data;
            });
    }
    static editAlarms(data: any, access_token: string, device_id: string) {
        return axios
            .patch(
                CONSTANTS.HOST + "api/devices/" + device_id + "/edit-alarms/",
                data,
                {
                    headers: { authorization: "Bearer " + access_token },
                }
            )
            .then((response: any) => response)
            .catch((err: any) => {
                console.log(err);
                return err.data;
            });
    }

    static getLatestParameter() {
        return CONSTANTS.HOST + "api/devices/REPLACE/data/parameters/latest/";
    }

    // "devices/<str:device_id>/data/<slug:data_type>/range/all/duration/<int:duration>/start_time/<str:start_time>/",

    static getLatestData(duration:number, parmlist: string [], throttle: boolean = false) {
        const throttle_str = throttle ? "&throttle=true" : ""
        const parmlist_str = "?parms=" + parmlist
        return CONSTANTS.HOST + "api/devices/REPLACE/data/parameters/range/all/duration/" + duration + "/start_time/latest/" + parmlist_str + throttle_str;
    }

    /*
     * Expect the following data dict:
     * device_id   : string
     * max_returns : number
     */
    static getTrendData (data: any, access_token: string) {
        return axios
            .post(CONSTANTS.HOST + "api/devices/hotstorage/trend/", data, {
                headers: { authorization: "Bearer " + access_token },
            })
            .then((response: any) => response)
            .catch((err: any) => {
                console.log(err);
                return err.data;
            });
    }


    static getHistoryData (device_id: string, data_type: string, start_time: string, end_time: string, max_returns:number) {
        //const now        = new Date ()
        //const end_time   = now.toISOString()
        //const start      = now;
        //start.setSeconds(start.getSeconds() - duration);
        //const start_time = start.toISOString()
        return CONSTANTS.HOST + "api/devices/" + device_id + "/historydata/?start=" + start_time + "&end=" + end_time + "&data_type=" + data_type + "&max=" + max_returns
    }


    
    static eraseHotstorage(data: any, access_token: string) {
        return axios
            .post(CONSTANTS.HOST + "api/devices/hotstorage/erase/", data, {
                headers: { authorization: "Bearer " + access_token },
            })
            .then((response: any) => response)
            .catch((err: any) => {
                console.log(err);
                return err.data;
            });
    }



    static  exportToFile(data: any, access_token: string) {
        console.log ("POST DATA:", data)
        return axios
            .post(CONSTANTS.HOST + "api/devices/hotstorage/chart/", data, {
                headers: { authorization: "Bearer " + access_token },
            })
            .then((response: any) => response)
            .catch((err: any) => {
                console.log(err);
                
                return err.data;
            });
    }

    static saveManualParms(device_id: string, data: any, access_token: string) {
        return axios
            .patch(CONSTANTS.HOST + "api/devices/" + device_id + "/setparms/", data, {
                headers: { authorization: "Bearer " + access_token },
            })
            .then((response: any) => response)
            .catch((err: any) => {
                console.log(err);
                return err.data;
            });
    }

    static exportEcg(data: any, access_token: string) {
        console.log ("Export ECG POST DATA:", data)
        return axios
            .post(CONSTANTS.HOST + "api/devices/hotstorage/ecg/", data, {
                headers: { authorization: "Bearer " + access_token },
            })
            .then((response: any) => response)
            .catch((err: any) => {
                console.log(err);
                
                return err.data;
            });
    }


    /*
     * Expect the following data dict:
     * device_id   : string
     */
    static getEventData (device_id: string) {
        return CONSTANTS.HOST + "api/v1/device/events/" + device_id + "/";
    }

    static getImage (device_id: string) {
        return CONSTANTS.HOST + "api/v1/device/images/" + device_id + "/"
    }

    static generateVideoLink(data: any, access_token: string) {
        return axios
            .post(CONSTANTS.HOST + "api/v1/device/pharlap/videolink/", data, {
                headers: { authorization: "Bearer " + access_token },
            })
            .then((response: any) => response)
            .catch((err: any) => {
                console.log(err);
                return err.data;
            });
    }
    static validateVideoLink() {
        return CONSTANTS.HOST + "api/v1/device/pharlap/videoaccess/";
    }


    // Retrieve possible admin users for a particular node
    static getPoolDevices (access_token: string, node_name: string) {
        return axios
            .get (CONSTANTS.HOST + "api/v1/sysadmin/devicepool/?node_name=" + node_name, {
                headers: { authorization: "Bearer " + access_token },
            })
            .then((response: any) => response.data)
            .catch((err: any) => console.log(err));
    } // getAdminUsers


    static addPoolDevice(data: any, access_token: string) {
        return axios
            .post(CONSTANTS.HOST + "api/v1/sysadmin/devicepool/", data, { 
                headers: { authorization: "Bearer " + access_token },
            })
            .then((response: any) => response)
            .catch((err: any) => {
                console.log(err);
                return err.response;
            });
    } // addPoolDevice

    static deletePoolDevice(device_id: string, access_token: string) {
        return axios
            .delete(CONSTANTS.HOST + "api/v1/sysadmin/devicepool/?device_id=" + device_id , {
                headers: { authorization: "Bearer " + access_token },
            })
            .then((response: any) => response)
            .catch((err: any) => {
                console.log(err);
                return err.data;
            });
    }

    static updateDeviceAssignments(access_token: string, data: any) {
        return axios
            .patch(CONSTANTS.HOST + "api/v1/sysadmin/devicepool/", data, { 
                headers: { authorization: "Bearer " + access_token },
            })
            .then((response: any) => response)
            .catch((err: any) => {
                console.log(err);
                return err.response;
            });
    } // addPoolDevice



}
export default DeviceService;
//export default new DeviceService();
