import { useState, useEffect, useRef } from "react";
import {
    useTheme,
    Grid,
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useCookies } from "react-cookie";
import UserService from "../../services/UserService";
import { RoleType, User } from "../../interfaces/user/User";
import { isBrowser } from "react-device-detect";
import UserMgmtDialog from "./UserMgmtDialog";


export interface OrgEntry {
    organization_id   : string;
    organization_name : string;
}


  
export const UserManagement = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [cookies, ,] = useCookies(["access_token"]);
    const [showUserDialog, setShowUserDialog] = useState(false); // Show new user input dialog
    const [showOK, setShowOK] = useState(false); // Show OK dialog on user add success
    const dialogMode = useRef<string> ("add")

    var token = cookies.access_token; // userCtx.user.token;
    var [users, setUsers] = useState<User[]>([]);

    const [userLevels, setUserLevels] = useState <RoleType []> ([])
    const [organizations, setOrganizations] = useState<OrgEntry[]>([]);
    const [filterText, setFilterText] = useState('');
    const [selectedUser, setSelectedUser] = useState <User> ();
    const [fetchUsers, setFetchUsers] = useState(0);

    const handleRowClick = (user: any) => {
        console.log ("handleRowClick", user)
        dialogMode.current = "edit"
        setSelectedUser (user)
        setShowUserDialog(true);
    };
  
    const handleCloseDialog = () => {
        console.log ("CLOSE DIALOG")
        setShowUserDialog(false);
    };
  
    const triggerRefresh = () => {
        console.log ("TRIGGER REFRESH")
        setFetchUsers (fetchUsers + 1)
    }
    //console.log ("user: ", user)

    const sortUsersByLastName = (users: User[]) => {
        return [...users].sort((a, b) => {
          // Handle cases where last_name could be undefined
          const lastNameA = a.last_name || ""; // Fallback to empty string if undefined
          const lastNameB = b.last_name || ""; // Fallback to empty string if undefined
          return lastNameA.localeCompare(lastNameB, undefined, { sensitivity: 'base' });
        });
      };

      
    // Fetch all users I can see in my sub-organizations
    useEffect(() => {
        UserService.getAllHierarchyUsers(token).then((response: any) => {

            // Sort the users by last name
            const sortedUSers = sortUsersByLastName (response.users)
            setUsers (sortedUSers)
        })
        .catch((error: any) => {
            console.log("Fetch error response:", error);
        })
        .finally(() => {});

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchUsers]);

    // Fetch my own profile to determine what kind of users I can add to the system.
    useEffect(() => {
        UserService.getMyProfile(token).then((response: any) => {
                //setUsers(response.data);
                console.log ("getMyProfile Response data:", response)
                //setRoles (response.userLevels.map((role:UserLevel) => ({
                //    value: role.roletype,
                //    description: role.name
                //    })))
                
                setUserLevels (response.userLevels);

                const orgData = response.organizations.map((org: OrgEntry) => ({
                    organization_id: org.organization_id,
                    organization_name: org.organization_name
                }));
                setOrganizations(orgData);

            })
            .catch((error: any) => {
                console.log("Fetch error response:", error);
            })
            .finally(() => {});

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  


    const addUserButton = () => {
        console.log ("addUserButton")
        dialogMode.current = "add"
        setShowUserDialog(true);
    }

 

    const handleCloseOK = (
        event: React.SyntheticEvent<unknown>,
        reason?: string
    ) => {
        if (reason !== "backdropClick") {
            setShowUserDialog(false);
            setShowOK(false);
        }
    };

    

    

    const fontVariant = isBrowser ? "body2" : "caption";
    //console.log ("user:", user)
    //console.log ("userLevels:", userLevels)
    //console.log ("selectedUserLevel:", selectedUserLevel)
    return (
        <div>
            <Grid container>
                {/* SUCCESS DIALOG */}
                <Dialog open={showOK} fullWidth={true} maxWidth={"md"}>
                    <DialogTitle> {t("success")}</DialogTitle>

                    <DialogContent>
                        <Typography>{t("user_added_successfully")}</Typography>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleCloseOK}> {t("close")}</Button>
                    </DialogActions>
                </Dialog>

                {/* NEW USER INPUT DIALOG  */}
                <UserMgmtDialog 
                    userData={selectedUser}
                    open={showUserDialog}
                    handleClose={handleCloseDialog}
                    userLevels = {userLevels}
                    dialogMode = {dialogMode.current}
                    organizations = { organizations}
                    triggerRefresh={triggerRefresh}
    
                />

                <Grid item xs={12} md={12}>
                <TextField
                    fullWidth
                    label="Search"
                    variant="outlined"
                    value={filterText}
                    placeholder={t("type_to_filter")}
                    onChange={(e) => setFilterText(e.target.value)}
                    sx={{ marginBottom: 2 }} // Spacing below the text field
                />
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    sx={{
                                        padding: "16px 6px",
                                    }}
                                >
                                    <Typography
                                        variant={fontVariant}
                                        fontWeight="500"
                                    >
                                        {t("lastname")}
                                    </Typography>
                                </TableCell>
                                <TableCell
                                    sx={{
                                        padding: "16px 6px",
                                        minWidth: 70,
                                    }}
                                >
                                    <Typography
                                        variant={fontVariant}
                                        fontWeight="500"
                                    >
                                        {t("firstname")}
                                    </Typography>
                                </TableCell>
                                <TableCell
                                    sx={{
                                        padding: "16px 6px",
                                    }}
                                >
                                    <Typography
                                        variant={fontVariant}
                                        fontWeight="500"
                                    >
                                        {t("username")}
                                    </Typography>
                                </TableCell>
                                <TableCell
                                    sx={{
                                        padding: "16px 6px",
                                    }}
                                >
                                    <Typography
                                        variant={fontVariant}
                                        fontWeight="500"
                                    >
                                        {t("email_address")}
                                    </Typography>
                                </TableCell>
                                <TableCell
                                    sx={{
                                        padding: "16px 6px",
                                    }}
                                >
                                    <Typography
                                        variant={fontVariant}
                                        fontWeight="500"
                                    >
                                        {t("phone")}
                                    </Typography>
                                </TableCell>

                                <TableCell sx={{ padding: "16px 6px" }} >
                                    <Typography variant={fontVariant} fontWeight="500" >
                                        {t("role")}
                                    </Typography>
                                </TableCell>

                                <TableCell sx={{ padding: "16px 6px" }} >
                                    <Typography variant={fontVariant} fontWeight="500" >
                                        {t("Customer Org")}
                                    </Typography>
                                </TableCell>


                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {users?.filter((user) => {
                                const searchText = filterText.toLowerCase();
                                return (
                                    user.last_name?.toLowerCase().includes(searchText) ||
                                    user.first_name?.toLowerCase().includes(searchText) ||
                                    user.username?.toLowerCase().includes(searchText) ||
                                    user.email?.toLowerCase().includes(searchText) ||
                                    user.phone?.toLowerCase().includes(searchText) ||
                                    (user.is_admin ? 'Yes' : 'No').toLowerCase().includes(searchText)
                                );
                                }).map((user: User) => (
                                    <TableRow
                                        key={user.username}
                                        hover
                                        onClick={() => handleRowClick(user)}
                                        sx={{
                                            "&:last-child td, &:last-child th": {
                                                border: 0,
                                            },
                                            "&:nth-of-type(odd)": {
                                                background: theme.palette.info.dark,
                                            },
                                        }}
                                    >
                                            <TableCell
                                                sx={{
                                                    padding: "6px 6px",
                                                }}
                                            >
                                                <Typography
                                                    variant={fontVariant}
                                                >
                                                    {user.last_name}
                                                </Typography>
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    padding: "6px 6px",
                                                }}
                                            >
                                                <Typography
                                                    variant={fontVariant}
                                                >
                                                    {user.first_name}
                                                </Typography>
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    padding: "6px 6px",
                                                }}
                                            >
                                                <Typography
                                                    variant={fontVariant}
                                                >
                                                    {user.username}
                                                </Typography>
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    padding: "6px 6px",
                                                }}
                                            >
                                                <Typography
                                                    variant={fontVariant}
                                                >
                                                    {user.email}
                                                </Typography>
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    padding: "6px 6px",
                                                }}
                                            >
                                                <Typography
                                                    variant={fontVariant}
                                                >
                                                    {user.phone}
                                                </Typography>
                                            </TableCell>

                                            <TableCell sx={{ padding: "6px 6px" }} >
                                                <Typography variant={fontVariant}>
                                                    {user.role?.name}
                                                </Typography>
                                            </TableCell>

                                            <TableCell sx={{ padding: "6px 6px" }} >
                                                <Typography variant={fontVariant}>
                                                    {user.organization_name}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>

            <Grid container mt={2}>
                <Grid item xs={6} md={4}>
                    <Button variant="contained" onClick={addUserButton}>
                        {t("add_user")}
                    </Button>
                </Grid>
            </Grid>
      
        </div>
    );
}; // ResetPassword
/*
                     <TextField
                                id="user_org"
                                label={t("user_org_id")}
                                type="text"
                                variant="outlined"
                                color="primary"
                                fullWidth
                                margin="dense"
                                onChange={(e) => {
                                    setUserOrg(e.target.value);
                                }}
                                sx={{
                                    input: {
                                        color: theme.palette.text.primary,
                                        background:
                                            theme.palette.background.default,
                                    },
                                    label: {
                                        color: theme.palette.text.primary,
                                    },
                                }}
                            />                          
                        }
                        */