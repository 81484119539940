import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import useFetch from "../../hooks/useFetch";
import OrgService from "../../services/OrganizationService";
import { Container, Grid, Button, Typography, Switch, FormControlLabel } from '@mui/material';
import TreeNavigator from './TreeNavigator';
import NodeDetails from './NodeDetails';
import AdminUsers from './AdminUsers';
import NodeChildren from './NodeChildren';
import DeviceChildren from "./DeviceChildren"

import { OrganizationHierarchyNode } from './types'; // Define your types in a separate file or within components
import { useState, useContext, useEffect, useRef } from 'react';
import { NodeEditDialog } from "./NodeEditDialog";
import { User } from "../../interfaces/user/User";
import { CustomerEditDialog} from "./CustomerEditDialog"
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@mui/icons-material/Add';
import { UserContext } from "../../context/UserContext";


interface FetchDataResult {
    hierarchy: OrganizationHierarchyNode[];
    loading: boolean;
    error: any;
}

function useFetchHierarchy(refresh: number): FetchDataResult {
    // console.log (" = = = = = fetchHierarchy = = = = = =", refresh)
    const [cookies] = useCookies(['access_token']);
    const [trigger, setTrigger] = useState(0);

    // Properly structured hook call
    const { data, loading, error } = useFetch({
        url: OrgService.getHierarchyNodes() + `?full_hierarchy=true&cachebust=${trigger}`,
        access_token: cookies.access_token,
    });

    const [hierarchy, setHierarchy] = useState<OrganizationHierarchyNode[]>([]);

    useEffect(() => {
        if (!loading && data) {
            setHierarchy(data); // Assuming 'data' is in the correct format
        }
    }, [data, loading]);

    useEffect(() => {
        // Increment trigger to refetch data when refresh is toggled
        if (refresh) {
            setTrigger(prev => prev + 1);
        }
    }, [refresh]);

    return { hierarchy, loading, error };
}


export const findNodeById = (nodes: OrganizationHierarchyNode[], nodeId: string) => {
    let result = null; // This will store the found node
    nodes.some(node => {
        if (String (node.id) === String (nodeId)) {
            result = node; // Node is found
            return true; // Stop searching
        }
        if (node.nodes.length > 0) {
            result = findNodeById(node.nodes, nodeId); // Recursively search in children
            return result !== null; // If found in children, stop searching
        }
        return false; // Continue searching
    });
    return result; // Return found node or null if not found
};


export const ServerManagement = () => {
   // const theme = useTheme();
    const { t } = useTranslation();

    const userCtx = useContext(UserContext);

    //const { t } = useTranslation();

    const [selectedNode, setSelectedNode] = useState<OrganizationHierarchyNode | null>(null);
    const [selectedNodeId, setSelectedNodeId] = useState<string | null>(null);
    const [nodes, setNodes] = useState<OrganizationHierarchyNode[]>([]);
    //const [expandedNodes, setExpandedNodes] = useState<string[]>([]);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [showCustomerDialog, setShowCustomerDialog] = useState(false);
    const [refreshData, setRefreshData] = useState(0);
    const { hierarchy } = useFetchHierarchy(refreshData); // Assume false initially or manage this state
    const [parentNode, setParentNode] = useState <OrganizationHierarchyNode | null> (null)
    const nodeDialogMode     = useRef <string> ("add")
    const customerDialogMode = useRef <string> ("add")
    const [isDeviceInfoActive, setIsDeviceInfoActive] = useState(false);
    const toggleDeviceInfo = () => {
      setIsDeviceInfoActive(!isDeviceInfoActive);
    };
  
    const getNodeInfoText = () => {
      switch (selectedNode?.node_type) {
        case "sysadmin" : return "Domains";
        case "domain"   : return "End Customers";
        case "customer" : return "Customer Details";
        default         : return ""; // Default or undefined node type
      }
    };

      
    // Recursive function to process nodes
    const processNodes = (orgs: any[], parentNode: any): OrganizationHierarchyNode[] => {
        if (!orgs) return []; // Return an empty array if there are no organizations to process
    
        // The node either has other OrganizationHierarchy nodes ("children") or
        // Customer Organizations ("organizations"), and they map to different fields.

        return orgs.map((org) => {
 
            // First, recursively process the children to get their details including their computed licenses
            const childNodes = processNodes(org.children || [], org);
    
            // Compute the total number of licenses assigned to the children of this node
            const CHILDREN_numLicenses = childNodes.reduce((total, child) => {
                return total + (child.licensePool || 0);
            }, 0);
    
            //console.log ("Processing:", org)
            return {
                id                  : String(org.id ? org.id : org.organization_id),
                node_name           : org.node_name,
                node_type           : org.node_type,
                description         : org.description,
                licensePool         : org.num_licenses,
                numAssignedLicenses : CHILDREN_numLicenses, // total licenses assigned to its children
                parentName          : parentNode.id, //org.parent ? String(org.parent) : parentNode.id,
                mgmtUsers           : org.owning_user || [],
                devicetypes         : org.devicetypes,
                nodes               : childNodes, // Attach processed child nodes with their own computed values
                customer            : org.customer,
                
                // Fields only populated on Organization leaf-nodes
                organization_id     : org.id,
                admin_email         : org.admin_email,
                sentio_type         : org.sentio_type,
                organization_name   : org.organization_name,
                data_resolution     : org.data_resolution,
                hotstore_duration   : org.hotstore_duration,
                ai_execution        : org.ai_execution,
                proxy_device_server : org.proxy_device_server,
                login_domain        : org.login_domain,
                ingestion_system    : org.ingestion_system,
                ingestion_topic     : org.ingestion_topic,
                ingestion_group     : org.ingestion_group,
                variant             : org.customer?.variant
            };
            
        });
    };
    

    // Helper function to check if the user exists in mgmtUsers
    const userInMgmtUsers = (mgmtUsers: User[], userName: string): boolean => {
        return mgmtUsers.some(user => user.username === userName);
    };
    
    // Recursive function to find sub-trees containing the specified user and their descendants
    const findSubTreesWithUserAndDescendants = (
        nodes: OrganizationHierarchyNode[], 
        userName: string
    ): OrganizationHierarchyNode[] => {
        // If the input list is empty, return an empty array
        if (!nodes || nodes.length === 0) {
        return [];
        }
    
        // Check if the root node itself contains the user
        const rootNode = nodes[0]; // Assuming nodes always starts with a single root node
    
        if (userInMgmtUsers(rootNode.mgmtUsers, userName)) {
        // Return the entire root node as the single sub-tree
        return [rootNode];
        }
    
        // Otherwise, collect all sub-trees containing the user starting from deeper levels
        return rootNode.nodes.flatMap(childNode => {
        // Recursively find sub-trees where the user appears
        const subTrees = findSubTreesWithUserAndDescendants([childNode], userName);
    
        // If a sub-tree was found starting from this child, return it
        return subTrees.length > 0 ? [childNode] : [];
        });
    };


    useEffect(() => {
        console.log("hierarchy:", hierarchy,  userCtx?.user?.username);
        if (!hierarchy) {
            return;
        }

        // We don't want the whole hierarchy. Only show the nodes starting from my own
        // home node and below.
        const nlist = processNodes(hierarchy, hierarchy[0]); // Use the recursive function
        //console.log("Processed Node List:", nlist);
        const filteredTree = findSubTreesWithUserAndDescendants(nlist, userCtx?.user?.username || "");
        console.log("filteredTree:", userCtx?.user?.username, filteredTree);
        setNodes(filteredTree);
        //setExpandedNodes(nlist.map(node => node.id));  // Expand only the first level
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hierarchy, userCtx?.user?.username]);

    useEffect (() => {
        if (!selectedNode) {
            setSelectedNode (nodes[0])
        } else {
            if (selectedNodeId) {
                const selNode = findNodeById (nodes, selectedNodeId)
                if (selNode) updateAssignedLicenses (selNode);
                setSelectedNode (selNode)
                
            }
        }
        if (!selectedNodeId) {
            setSelectedNodeId(nodes[0]?.id);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nodes, ])

    useEffect (() => {
        if (!selectedNode) {
            return;
        }
        const parent = findNodeById (nodes, selectedNode.parentName);
        setParentNode (parent)
        //console.log ("New parent node:", parent)
        //setParentNode (selectedNode)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedNode])

    // Update the Assigned Licenses for a given node.
    const updateAssignedLicenses = (node: OrganizationHierarchyNode): OrganizationHierarchyNode => {
        // Sum up the `numAssignedLicenses` for all immediate children
        const childLicensePools = node.nodes.reduce(
            (total, child) => total + (child.licensePool ?? 0),
            0
        );
        
        // Assign the calculated sum to `numAssignedLicenses` for the parent node
        node.numAssignedLicenses = childLicensePools;
        
        // Return the modified node
        return node;
    };
      



    // This function will be called when a node is selected, either from the TreeNavigator or the NodeChildren table
    const handleSelectNode = (nodeId: string) => {
        const node = findNodeById(nodes, nodeId);  // Assuming you have a function to find a node by ID
        setSelectedNode(node || null);
        setSelectedNodeId(nodeId);
    };
    
    const updateNodeInTree = (tree: OrganizationHierarchyNode[], updatedNode: OrganizationHierarchyNode):OrganizationHierarchyNode [] => {
        // Helper function to update the node recursively
        const updateNodeRecursively = (
          nodes: OrganizationHierarchyNode[]
        ): OrganizationHierarchyNode[] => {
          return nodes.map((node) => {
            // If this is the node to update, return the updated node
            if ((node.id === updatedNode.id) || (node.organization_id === updatedNode.organization_id)){
              return updatedNode;
            }
      
            // Otherwise, recursively update the children
            if (node.nodes) {
              return {
                ...node,
                nodes: updateNodeRecursively(node.nodes),
              };
            }
      
            return node; // If no match found, return node as is
          });
        };
      
        // Start the recursive update from the root level
        return updateNodeRecursively(tree);
      }
      
      const handleNodeChanged = (changedNode: OrganizationHierarchyNode) => {
        //console.log("SystemManagement.handleNodeChanged", changedNode, nodes);
      
        // Update the node in the tree
        const newNodeTree = updateNodeInTree(nodes, changedNode);
      
        // Find the parent node by ID (or similar identifier)
        const parentNode: OrganizationHierarchyNode | null = findNodeById(newNodeTree, changedNode.parentName);
        //console.log ("  -- Find the parent node by ID: ", changedNode.parentName, parentNode)
      
        // Update assigned licenses if the parent node was found
        let updatedParentNode: OrganizationHierarchyNode | null = parentNode;
      
        if (parentNode) {
          updatedParentNode = updateAssignedLicenses(parentNode);
          //console.log ("  -- Updated the parent license assignment from children pool:, updatedParentNode")
        }
      
        // If `updatedParentNode` is still non-null, update it in the tree
        if (updatedParentNode) {
            //console.log ("  -- setNodes(updateNodeInTree ", updatedParentNode)
            setNodes(updateNodeInTree(newNodeTree, updatedParentNode));
            //setSelectedNode (updatedParentNode)
            //setSelectedNodeId (updatedParentNode.id)
        }
      };
      

    // Recursively remove a node by its ID from the parent node's children
    const removeNodeFromTree = (nodes: OrganizationHierarchyNode[], parentId: string, nodeId: string ): OrganizationHierarchyNode[] => {
        return nodes.map(node => {
        // If the current node matches the parent ID, update its children by filtering out the node to be removed
        if (node.id === parentId) {
            return {
            ...node, // spread operator to ensure immutability
            nodes: node.nodes.filter(child => child.id !== nodeId),
            };
        }
    
        // Otherwise, recursively search through children
        return {
            ...node,
            nodes: removeNodeFromTree(node.nodes, parentId, nodeId),
        };
        });
    };
        

    const handleNodeDeleted = (node_id: string, parent_id: string) => {
        console.log ("handleNodeDeleted:", node_id, parent_id)
        const updatedNodes = removeNodeFromTree(nodes, parent_id, node_id);
        setNodes(updatedNodes);
        const parentNode = findNodeById (updatedNodes, parent_id);
        if (parentNode) {
            setSelectedNode (parentNode)
            setSelectedNodeId (parent_id)    
        }
        //setRefreshData(refreshData + 1)
    }

    const handleNodeUpdate = (updatedNode: OrganizationHierarchyNode) => {
        console.log ("NodeChildren.handleNodeUpdate", updatedNode)
        handleNodeChanged (updatedNode)
        //setRefreshData(refreshData + 1)

        //setSelectedNodeId (updatedNode.id)    

    };




    const handleAddNode: () => void = () => {
        console.log ("Add Node:", selectedNode?.node_type)
        if (selectedNode?.node_type === "sysadmin") {
            // Add a new Domain node
            nodeDialogMode.current = "add"
            setShowEditDialog (true)
        }
        if (selectedNode?.node_type === "domain") {
            // Add a new End Customer node
            console.log ("Add new customer to parent", parentNode)

            customerDialogMode.current = "add"
            setShowCustomerDialog (true)
        }
    }

    // Define the function with the correct type
    const handleEdit: () => void = () => {
        //setEditDialogMode ("edit");
        console.log ("Edit Node:", selectedNode?.node_type)
        if ((selectedNode?.node_type === "domain") || (selectedNode?.node_type === "sysadmin")) {
            // Add a new Domain node
            nodeDialogMode.current = "edit"
            setShowEditDialog (true)
        }
        if (selectedNode?.node_type === "customer") {
            // Add a new End Customer node
            customerDialogMode.current = "edit"
            setShowCustomerDialog (true)
        }
       
    };



    // Function to recursively insert a new node into the hierarchy
    const insertNodeIntoTree = (
        nodes: OrganizationHierarchyNode[], 
        parentId: string, 
        newNode: OrganizationHierarchyNode
    ): OrganizationHierarchyNode[] => {
        return nodes.map(node => {
        // If the current node matches the parent ID, add the new node to its children
        if (node.id === parentId) {
            return {
            ...node, // spread operator to maintain immutability
            nodes: [...node.nodes, newNode],
            };
        }
    
        // Otherwise, recursively search through children
        return {
            ...node,
            // eslint-disable-next-line
            nodes: insertNodeIntoTree(node.nodes, parentId, newNode),
        };
        });
    };
  
    // Function to insert a new node by specifying the parent node ID and new node instance
    const handleInsertNode = (node_id: string) => {
        //console.log ("handleInsertNode:", node_id)
        //setRefreshData(refreshData + 1)
    };


    return (
        <Container maxWidth="lg">


            <NodeEditDialog
                openState={showEditDialog}
                setOpenState={setShowEditDialog}
                nodeData={selectedNode ?? undefined}
                parentNode={parentNode}
                dialogMode = { nodeDialogMode.current}
                setNodeData = {setSelectedNode}
                onNodeDeleted={handleNodeDeleted}
                triggerRefresh={() => setRefreshData(refreshData + 1)}
                childNodeType="domain"
            />

            <CustomerEditDialog
                openState={showCustomerDialog}
                setOpenState={setShowCustomerDialog}
                hierarchyNode={selectedNode ?? undefined}
                nodeData={selectedNode?.customer ?? undefined}
                dialogMode = { customerDialogMode.current }
                onNodeUpdate = {handleNodeUpdate}
                triggerRefresh={() => setRefreshData(refreshData + 1)}
                parentNode = { customerDialogMode.current === "add" ? selectedNode : parentNode }
                childNodeType = { "customer"}
                onNodeDeleted={handleNodeDeleted}
                onNodeAdded={handleInsertNode}
            />


          <Grid container spacing={3}>
            <Grid item xs={12} md={3} sx={{paddingRight: "5px"}}>
                <Typography variant="h5" gutterBottom sx={{color: (theme) => theme.palette.text.primary}}>
                    {t('Server Content')}
                </Typography>

                {nodes.length > 0 && 
                <TreeNavigator
                    nodes={nodes}
                    onSelectNode={(nodeId) => {
                        const node = findNodeById(nodes, nodeId);
                        setSelectedNode(node !== undefined ? node : null);
                        setSelectedNodeId(nodeId);
                    }}
                    selected={selectedNodeId ?? ''}
                />
                }
            </Grid>

            <Grid item xs={12} md={9}>
              <Grid container spacing={1} sx={{
                color: (theme) => theme.palette.text.primary
                }}>

                <Grid item xs={6}>
                    <Typography variant="h5" gutterBottom sx={{color: (theme) => theme.palette.text.primary}}>
                            {t("summary_info")}
                    </Typography>

                  <NodeDetails node={selectedNode} onEdit={handleEdit} />

                    <Grid item xs={12} container direction="column" alignItems="flex-end" style={{ marginTop: '8px' }}>
                        <Button variant="outlined"
                            startIcon = { <EditIcon /> }
                            color = "primary"
                            onClick = { handleEdit }
                        >
                            {t("edit")}
                        </Button>
                    </Grid>
                </Grid>

                { selectedNode && (
                    <Grid item xs={6} sm={6}>
                        <Typography variant="h5" gutterBottom>
                            {t("admin_users")}
                        </Typography>

                        <AdminUsers node={selectedNode} />
                        {/*
                        <Grid item xs={12} container direction="column" alignItems="flex-end" style={{ marginTop: '8px' }}>
                            <Button variant="outlined"
                                startIcon={<AddIcon />}
                                color="primary"
                                sx={{backgroundColor: "white"}}
                                onClick={handleAssignAdmin}
                            >
                                Assign Admin
                            </Button>
                        </Grid>
                        */}

                    </Grid>
                )}
                </Grid>

                    <Grid container spacing={2} alignItems="center">
                        <Grid item >
                            <FormControlLabel
                            control={<div />} // Empty control as placeholder
                            label={<Typography variant="h5" sx={{ color: (theme) => isDeviceInfoActive ? theme.palette.text.disabled : theme.palette.text.primary, cursor: 'pointer' }}>
                                {getNodeInfoText()}
                            </Typography>}
                            labelPlacement="end"
                            onClick={() => setIsDeviceInfoActive(false)}
                            sx={{ marginRight: 0 }} // Adjust spacing if necessary
                            />
                        </Grid>
                        <Grid item >
                            <Switch
                            checked={isDeviceInfoActive}
                            onChange={toggleDeviceInfo}
                            inputProps={{ 'aria-label': 'Device info toggle' }}
                            sx={{
                                '& .MuiSwitch-track': {
                                backgroundColor: (theme) => theme.palette.primary.main,
                                },
                                '& .MuiSwitch-thumb': {
                                color: (theme) => theme.palette.primary.main,
                                }
                            }}
                            />
                        </Grid>
                        <Grid item >
                            <FormControlLabel
                            control={<div />} // Empty control as placeholder
                            label={<Typography variant="h5" sx={{ color: (theme) => isDeviceInfoActive ? theme.palette.text.primary : theme.palette.text.disabled, cursor: 'pointer' }}>
                                Device Pool
                            </Typography>}
                            labelPlacement="end"
                            onClick={() => setIsDeviceInfoActive(true)}
                            sx={{ marginLeft: 0 }} // Adjust spacing if necessary
                            />
                        </Grid>
                        <Grid item xs={12}>
                        { isDeviceInfoActive && (<div><DeviceChildren
                            node={selectedNode} 
                            onNodeChanged={handleNodeChanged}
                            onDialogClose={() => setRefreshData(refreshData + 1)}
                            onNodeDeleted={handleNodeDeleted}
                            onNodeAdded={handleInsertNode}
                            nodeParent={parentNode}
                        
                            />
                            
                            </div>
                        )}
                        { !isDeviceInfoActive &&  (<div><NodeChildren 
                            node={selectedNode} 
                            onSelectNode={handleSelectNode}
                            setSelectedNode={setSelectedNode}
                            onNodeChanged={handleNodeChanged}
                            onDialogClose={() => setRefreshData(refreshData + 1)}
                            onNodeDeleted={handleNodeDeleted}
                            onNodeAdded={handleInsertNode}
                            nodeParent={parentNode}
                            />

                                { selectedNode?.node_type !== "customer" && <Grid container style={{ marginTop: 8 }} justifyContent="flex-end">
                                <Button variant="outlined"
                                    color="primary"
                                    startIcon={<AddIcon />}
                                    onClick={handleAddNode}
                                >
                                    { selectedNode?.node_type !== "domain" ? "Add Domain" : "Add CUstomer" }
                                </Button>
                            </Grid>
                            }
                            </div>
                        )}
                        </Grid>
                    </Grid>

                
                

              </Grid>

            </Grid>

        </Container>
      );
};

